import { createAction, createAsyncAction } from "typesafe-actions";
import { ICenter, ICenterDistrictsRequest, ICenterRequest as ICenterTrucksRequest, ICentersRequest, ICentersWithStatsRequest, IDistrict, IFetchCentersPayload } from "../../models/Center";
import { ISurveyor } from "src/models/Surveyor";
import { IOrgStatistics, IOrgStatisticsRequest } from "src/models/UserStatistics";
export const setCenterId = createAction('SET_CENTER_ID')<string | undefined>();
export const reset = createAction('RESET')<void>();

export const getCenters = createAsyncAction(
  "org/ORG_CENTERS_REQUEST",
  "org/ORG_CENTERS_SUCCESS",
  "org/ORG_CENTERS_FAILURE"
)<ICentersRequest, IFetchCentersPayload, Error>();

export const getCentersWithStats = createAsyncAction(
  "org/ORG_CENTERS_WITH_STATS_REQUEST",
  "org/ORG_CENTERS_WITH_STATS_SUCCESS",
  "org/ORG_CENTERS_WITH_STATS_FAILURE"
)<ICentersWithStatsRequest, ICenter[], Error>();

export const getCenterTrucks = createAsyncAction(
  "org/ORG_CENTER_WITH_TRUCKS_REQUEST",
  "org/ORG_CENTER_WITH_TRUCKS_SUCCESS",
  "org/ORG_CENTER_WITH_TRUCKS_FAILURE"
)<ICenterTrucksRequest, ICenter, Error>();

export const getCenterDistricts = createAsyncAction(
  "org/ORG_CENTER_DISTRICTS_REQUEST",
  "org/ORG_CENTER_DISTRICTS_SUCCESS",
  "org/ORG_CENTER_DISTRICTS_FAILURE"
)<ICenterDistrictsRequest, IDistrict[], Error>();

export const resetOrgUsers = createAction('RESET_ORG_USERS')<void>()

export const fetchOrgStatistics = createAsyncAction(
  'org/FETCH_ACTIVE_USERS_REQUEST',
  'org/FETCH_ACTIVE_USERS_SUCCESS',
  'org/FETCH_ACTIVE_USERS_FAILURE'
)<IOrgStatisticsRequest, IOrgStatistics, Error>();

export const fetchSurveyors = createAsyncAction(
  'org/FETCH_SURVEYORS_REQUEST',
  'org/FETCH_SURVEYORS_SUCCESS',
  'org/FETCH_SURVEYORS_FAILURE'
)<string, ISurveyor[], Error>();