import { createAction, createAsyncAction } from "typesafe-actions";
import { IBluetoothPeripheral, IScanRequest, IUploadBleLogsRequest } from "../../models";
import { IRpiWifiConfig, IRpiStatus } from "../../models/RpiWifiConfig";

export const scanDevices = createAction('SCAN_BLUETOOTH_DEVICES')<void>();
export const subscribeRpiStatus = createAction('SUBSCRIBE_RPI_STATUS_UPDATES')<void>();
export const stopScanningDevices = createAction('STOP_SCANNING_BLUETOOTH_DEVICES')<void>();

export const wifiConfigurationChanged = createAction('WIFI_CONFIGURATION_CHANGED')<boolean>();

export const deviceDiscovered = createAction('BLUETOOTH_DEVICE_DISCOVERED')<IBluetoothPeripheral>();

export const reset = createAction('RESET')<void>();

export const connectToRpiDevice = createAsyncAction(
    "bluetooth/CONNECT_RPI_REQUEST",
    "bluetooth/CONNECT_RPI_SUCCESS",
    "bluetooth/CONNECT_RPI_FAILURE"
)<String, String, Error>();

export const disconnectFromRpiDevice = createAsyncAction(
    "bluetooth/DISCONNECT_RPI_REQUEST",
    "bluetooth/DISCONNECT_RPI_SUCCESS",
    "bluetooth/DISCONNECT_RPI_FAILURE"
)<String, boolean, Error>();

export const connectToMoistureDevice = createAsyncAction(
    "bluetooth/CONNECT_MOISTURE_REQUEST",
    "bluetooth/CONNECT_MOISTURE_SUCCESS",
    "bluetooth/CONNECT_MOISTURE_FAILURE"
)<String, String, Error>();

export const scanImageUsingRpi = createAsyncAction(
    "bluetooth/SCAN_IMAGE_REQUEST",
    "bluetooth/SCAN_IMAGE_SUCCESS",
    "bluetooth/SCAN_IMAGE_FAILURE"
)<IScanRequest, boolean, Error>();

export const uploadBleLogs = createAsyncAction(
    "bluetooth/UPLOAD_BLE_LOGS_REQUEST",
    "bluetooth/UPLOAD_BLE_LOGS_SUCCESS",
    "bluetooth/UPLOAD_BLE_LOGS_FAILURE"
)<IUploadBleLogsRequest, boolean, Error>();

export const configueRpiWifi = createAsyncAction(
    "bluetooth/CONFIGURE_RPI_WIFI_REQUEST",
    "bluetooth/CONFIGURE_RPI_WIFI_SUCCESS",
    "bluetooth/CONFIGURE_RPI_WIFI_FAILURE"
)<IRpiWifiConfig, boolean, Error>();

export const getRpiStatus = createAsyncAction(
    "bluetooth/RPI_STATUS_REQUEST",
    "bluetooth/RPI_STATUS_SUCCESS",
    "bluetooth/RPI_STATUS_FAILURE"
)<void, IRpiStatus, Error>();

export const rpiHeartbeat = createAsyncAction(
    "bluetooth/RPI_HEARTBEAT_REQUEST",
    "bluetooth/RPI_HEARTBEAT_SUCCESS",
    "bluetooth/RPI_HEARTBEAT_FAILURE"
)<void, void, Error>();