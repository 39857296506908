import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { Auth as Authorization } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import * as LoginActions from '../../redux/actions/loginAction';
import { LOGIN_FAILED_MESSAGE } from "../../strings"
import { Button, FormControl, Icon, IconButton, Input, VStack, Text, Box, KeyboardAvoidingView } from 'native-base';
import { containerStyle } from 'src/styles/containerStyle';
import { inputStyle } from 'src/styles/inputStyle';
import ProgressBar from 'src/components/ProgressBar';
import InweonLogo from 'src/components/InweonLogo';
import * as AppActions from '../../redux/actions/appAction';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { formProps } from 'src/styles/formProps';
interface LoginFormState {
    username: string,
    password: string
}

export default function LoginPage({ navigation }) {

    const globalLogoutResponse = useSelector((store: IStore) => store.login.globalLogoutState.response);

    useEffect(() => {
        if (globalLogoutResponse === "SUCCESS") {
            login()
        } else if (globalLogoutResponse === "FAILED") {
            setShowLoadingIndicator(false)
            setErrors({
                ...errors,
                formError: LOGIN_FAILED_MESSAGE
            });
        }
    }, [globalLogoutResponse]);

    const [securePasswordEntry, setSecurePasswordEntry] = React.useState(true);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

    const [formData, setData] = React.useState<LoginFormState>({
        username: '',
        password: ''
    });

    const [errors, setErrors] = React.useState({});

    const validate = () => {
        if (formData.username === undefined
            || formData.username.length === 0) {
            setErrors({
                username: 'Username is required'
            });
            return false;
        } else if (formData.password === undefined
            || formData.password.length === 0) {
            setErrors({
                password: 'Password is required'
            });
            return false;
        } else if (formData.password.length < 8) {
            setErrors({
                password: 'Password must be at least 8 characters'
            });
            return false;
        }

        return true;
    };

    const dispatch = useDispatch();

    const resetState = async () => {
        try {
            await clearAppData();
            dispatch(AppActions.logout());
        } catch (e) {
            console.log('error signing out: ', e);
        }
    }

    const clearAppData = async function () {
        try {
            const keys = await AsyncStorage.getAllKeys();
            await AsyncStorage.multiRemove(keys);
            await AsyncStorage.clear();
            console.log('App data cleared.');
        } catch (error) {
            console.error('Error clearing app data.');
        }
    }

    const login = async (retryCount = 0) => {
        try {
            await resetState()
            const resp = await Authorization.signIn(formData.username, formData.password)
            if ('challengeName' in resp && resp['challengeName'] === "NEW_PASSWORD_REQUIRED") {
                navigation.navigate('ForceChangePassword', {
                    user: resp
                })
                setData({
                    ...formData,
                    password: ''
                })
                setShowLoadingIndicator(false)
                return
            }
            if (resp['signInUserSession'] !== undefined) {
                dispatch(LoginActions.setUserLoggedIn("LOGGED_IN"));
                console.log('user logged in')
            } else {
                dispatch(LoginActions.setUserLoggedIn("NOT_LOGGED_IN"));
                setErrors({
                    formError: LOGIN_FAILED_MESSAGE
                });
            }
        } catch (e: any) {
            if (e.message === "Access Token has been revoked") {
                if (retryCount < 3) {
                    await login(retryCount + 1)
                    return
                }
            }

            setErrors({
                ...errors,
                formError: e.message
            });
            setShowLoadingIndicator(false)
            dispatch(LoginActions.setUserLoggedIn("NOT_LOGGED_IN"));
        }
    }

    const handleLogin = async () => {
        setShowLoadingIndicator(true)
        dispatch(LoginActions.globalLogout.request(formData.username));
    }

    const handleForgotPassword = async () => {
        navigation.navigate('ForgotPasswordPage')
    }

    const handleSignupClicked = async () => {
        navigation.navigate('SignupPage')
    }

    const onSubmit = () => {
        const isValid = validate();
        if (isValid) {
            // submit the data
            handleLogin()
        }
    };

    return (
        <Box variant={"main"}
            style={containerStyle.mainContainer}>
            <ProgressBar isVisibile={showLoadingIndicator} progressText="Logging in..." />
            <ScrollView automaticallyAdjustKeyboardInsets={true}>
                <InweonLogo title={"Sign in to your GRAMS account"} />
                <VStack
                    width={formProps.width}
                    style={containerStyle.formContainer} mx="3">
                    <FormControl isRequired>
                        <FormControl.Label>Username</FormControl.Label>
                        <Input
                            style={inputStyle.nativeInput}
                            isInvalid={'username' in errors}
                            placeholder="username"
                            onChangeText={value => setData({
                                ...formData,
                                username: value
                            })} />
                        {'username' in errors ? <FormControl.ErrorMessage>{errors['username']}</FormControl.ErrorMessage> : null}

                        <FormControl.Label>Password</FormControl.Label>
                        <Input
                            secureTextEntry={securePasswordEntry}
                            style={inputStyle.nativeInput}
                            isInvalid={'password' in errors}
                            InputRightElement={
                                <IconButton
                                    onPress={() => {
                                        setSecurePasswordEntry(!securePasswordEntry)
                                    }}
                                    icon={<MaterialCommunityIcons name="eye" />} />
                            }
                            placeholder="********"
                            onSubmitEditing={onSubmit}
                            onChangeText={value => setData({
                                ...formData,
                                password: value
                            })} />
                        {'password' in errors ? <FormControl.ErrorMessage>{errors['password']}</FormControl.ErrorMessage> : null}
                    </FormControl>
                    <Button
                        onPress={onSubmit}
                        mt="5">
                        Submit
                    </Button>
                    {'formError' in errors ? <Text color="red.500" mt="2">{errors['formError'] || ""}</Text> : null}
                </VStack>

                <Button
                    variant="link"
                    onPress={handleForgotPassword}>
                    Forgot password?
                </Button>
                <Button variant="link"
                    onPress={handleSignupClicked}>
                    Sign up for an account
                </Button>
            </ScrollView>
        </Box>
    );
}