import * as Effects from "redux-saga/effects";

import * as ProductReleaseAction from "../actions/productReleaseAction";
import * as api from "../../api/productReleases";
import { showErrorMessage } from "./shared";

const call: any = Effects.call;
const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;

export function* getProductReleases(action: ReturnType<typeof ProductReleaseAction.getProductReleases.request>): any {
  try {
    const response = yield call(api.getProductReleases, action.payload);
    yield put(ProductReleaseAction.getProductReleases.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(ProductReleaseAction.getProductReleases.failure(error));
  }
}
/*
 * WATCHERS
 */

export function* watchGetProductReleases() {
  yield takeEvery(ProductReleaseAction.getProductReleases.request, getProductReleases);
}

export default function* root() {
  yield all([fork(watchGetProductReleases)]);
}