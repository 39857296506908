import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Auth as Authorization } from 'aws-amplify';
import { containerStyle } from 'src/styles/containerStyle';
import { Button, FormControl, Input, VStack, Text, Box } from 'native-base';
import { inputStyle } from 'src/styles/inputStyle';
import ProgressBar from 'src/components/ProgressBar';
import { formProps } from 'src/styles/formProps';

interface ChangePasswordFormState {
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
}

export default function ChangePasswordPage({ navigation }) {

    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

    const [formData, setData] = React.useState<ChangePasswordFormState>({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    const [errors, setErrors] = React.useState({});

    const validate = () => {
        if (formData.currentPassword === undefined
            || formData.currentPassword.length === 0) {
            setErrors({
                currentPassword: 'Current password is required'
            });
            return false;
        } else if (formData.newPassword === undefined
            || formData.newPassword.length === 0) {
            setErrors({
                newPassword: 'New password is required'
            });
            return false;
        } else if (formData.confirmNewPassword === undefined
            || formData.confirmNewPassword.length === 0) {
            setErrors({
                confirmNewPassword: 'Confirm new password is required'
            });
            return false;
        }

        if (formData.newPassword !== formData.confirmNewPassword) {
            setErrors({
                confirmNewPassword: 'Passwords do not match'
            });
            return false;
        }

        return true;
    };

    const handleChangePassword = async (retryCount = 0) => {
        setShowLoadingIndicator(true)
        try {
            const user = await Authorization.currentAuthenticatedUser();
            const changePasswordResp = await Authorization
                .changePassword(user, formData.currentPassword, formData.newPassword)

            setShowLoadingIndicator(false)
            navigation.navigate('Home')
        } catch (e: any) {
            console.log('error occurred', e)

            if (e.message === "Access Token has been revoked") {
                if (retryCount < 3) {
                    await handleChangePassword(retryCount + 1)
                    return
                }
            }
            setErrors({
                formError: e.message
            });
            setShowLoadingIndicator(false)
        }
    }

    const onSubmit = () => {
        const isValid = validate();
        console.log('isValid', isValid)
        if (isValid) {
            // submit the data
            handleChangePassword()
        }
    };

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isVisibile={showLoadingIndicator} progressText="Setting new password..." />
            <ScrollView automaticallyAdjustKeyboardInsets={true}>
                <VStack width={formProps.width}
                    style={containerStyle.formContainer} mx="3">
                    <FormControl isRequired>
                        <FormControl.Label>Current Password</FormControl.Label>
                        <Input
                            placeholder="Current Password"
                            type="password"
                            variant="filled"
                            style={inputStyle.nativeInput}
                            onChangeText={(text) => setData({ ...formData, currentPassword: text })}
                            value={formData.currentPassword}
                            secureTextEntry={true}
                        />
                        {'currentPassword' in errors ? <Text color="red.500" mt="2">{errors['currentPassword'] || ""}</Text> : null}

                        <FormControl.Label mt="3">New Password</FormControl.Label>
                        <Input
                            placeholder="New Password"
                            type="password"
                            variant="filled"
                            style={inputStyle.nativeInput}
                            onChangeText={(text) => setData({ ...formData, newPassword: text })}
                            value={formData.newPassword}
                            secureTextEntry={true}
                        />

                        {'newPassword' in errors ? <Text color="red.500" mt="2">{errors['newPassword'] || ""}</Text> : null}

                        <FormControl.Label mt="3">Confirm New Password</FormControl.Label>
                        <Input
                            placeholder="Confirm New Password"
                            type="password"
                            variant="filled"
                            style={inputStyle.nativeInput}
                            onSubmitEditing={onSubmit}
                            onChangeText={(text) => setData({ ...formData, confirmNewPassword: text })}
                            value={formData.confirmNewPassword}
                            secureTextEntry={true}
                        />
                        {'confirmNewPassword' in errors ? <Text color="red.500" mt="2">{errors['confirmNewPassword'] || ""}</Text> : null}

                    </FormControl>
                    <Button onPress={onSubmit} mt="5">
                        Set new password
                    </Button>
                    {'formError' in errors ? <Text color="red.500" mt="2">{errors['formError'] || ""}</Text> : null}
                </VStack>
            </ScrollView>


        </Box>
    );
}