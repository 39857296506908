import { createAction, createAsyncAction } from "typesafe-actions";
import { ITokenRequest } from "../../models";

export const setDeviceToken = createAction('SET_REGISTRATION_TOKEN')<string>();
export const setDeviceId = createAction('SET_DEVICE_ID')<string>();
export const reset = createAction('RESET')<void>();

export const registerToken = createAsyncAction(
    "notification/REGISTER_DEVICE_TOKEN_REQUEST",
    "notification/REGISTER_DEVICE_TOKEN_SUCCESS",
    "notification/REGISTER_DEVICE_TOKEN_FAILURE"
)<ITokenRequest, Boolean, Error>();