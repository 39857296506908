import * as Effects from "redux-saga/effects";

import * as NotificationToken from "../actions/notificationAction";
import * as api from "../../api/notification";
import { showErrorMessage } from "./shared";

const call: any = Effects.call;
const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;

export function* registerDeviceToken(action: ReturnType<typeof NotificationToken.registerToken.request>): any {
  try {
    const response = yield call(api.registerDeviceToken, action.payload);
    yield put(NotificationToken.registerToken.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(NotificationToken.registerToken.failure(error));
  }
}
/*
 * WATCHERS
 */

export function* watchRegisterDeviceToken() {
  yield takeEvery(NotificationToken.registerToken.request, registerDeviceToken);
}

export default function* root() {
  yield all([fork(watchRegisterDeviceToken)]);
}