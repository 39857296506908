import React, { useEffect } from 'react';

import * as AnalyseAction from '../../redux/actions/analyseAction';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import uuid from 'react-native-uuid';

import { useSelector, useDispatch } from "react-redux";
import * as AppActions from '../../redux/actions/appAction';
import { MANUAL_UPLOAD_TIMEOUT, SCAN_TIMEOUT, UPLOAD_TIMEOUT } from '../../constants';
import { IStore } from '../../redux/reducers';
import { IManualJobEntryInput, JobStatus, isImageScanningOrUploading, isScanError } from '../../models';
import MeasurementInfo from 'src/components/MeasurementInfo';
import InfoStatusBar from 'src/components/InfoStatusBar';
import { Auth as Authorization } from 'aws-amplify';

import { Box } from 'native-base';
import CustomAlertDialog from 'src/components/CustomAlertDialog';
import ProgressBar from 'src/components/ProgressBar';
import FabButtons from 'src/components/FabButtons';
import { containerStyle } from 'src/styles/containerStyle';
import Geolocation from '@react-native-community/geolocation';

export default function InfoPage({ navigation }) {

    const hideDialog = () => setDialogVisible(false);

    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [dialogStatusMessage, setDialogStatusMessage] = React.useState("");

    const [waiting, setWaiting] = React.useState(false);

    const jobStatus = useSelector((store: IStore) => store.job.currentJobStatus);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isScanError(jobStatus)) {
            displayScanError(jobStatus);
        }
        if (jobStatus === JobStatus.JOB_IMAGE_UPLOADED
            || isScanError(jobStatus)) {
            setWaiting(false);
        }
    }, [jobStatus]);

    const checkAuthAndManualUpload = async (payload: IManualJobEntryInput) => {
        Authorization.currentUserInfo().then(async () => {
            await processManualJob(payload)
        }).catch((e) => {
            console.log('error occurred', e)
            dispatch(AppActions.forceLogout());
        });
    }

    const checkAuthAndUpload = async (data: any, source = "image") => {
        Authorization.currentUserInfo().then(async () => {
            setResponseAndUploadFile(data, source)
        }).catch((e) => {
            console.log('error occurred', e)
            dispatch(AppActions.forceLogout());
        });
    }

    const checkAuthAndDoubleSidedUpload = async (data: any, source = "image") => {
        Authorization.currentUserInfo().then(async () => {
            setResponseAndUploadDoubleSidedFile(data, source)
        }).catch((e) => {
            console.log('error occurred', e)
            dispatch(AppActions.forceLogout());
        });
    }

    const checkAuthAndScan = async () => {
        Authorization.currentUserInfo().then(async () => {
            checkStatusAndStartScan()
        }).catch((e) => {
            console.log('error occurred', e)
            dispatch(AppActions.forceLogout());
        });
    }

    const processManualJob = async (payload: IManualJobEntryInput) => {
        setWaiting(true)
        setTimeout(() => {
            setWaiting(false)
        }, MANUAL_UPLOAD_TIMEOUT)

        const jobId = uuid.v4().toString();

        Geolocation.getCurrentPosition(
            (info) => {
                const location = `${info.coords.latitude},${info.coords.longitude}`;
                dispatch(AnalyseAction.initiateManualJob.request({
                    jobId: jobId,
                    manualJobEntryInput: payload,
                    location: location
                }));
            },
            (error) => {
                console.log('Error occurred while fetching location', error)
                dispatch(AnalyseAction.initiateManualJob.request({
                    jobId: jobId,
                    manualJobEntryInput: payload,
                    location: ""
                }));
            });
    }

    const setResponseAndUploadFile = async (data: any, source = "image") => {
        if (data?.assets === undefined ||
            data?.assets === null ||
            data?.assets.length === 0) {
            return
        }
        setWaiting(true)
        setTimeout(() => {
            setWaiting(false)
        }, UPLOAD_TIMEOUT)
        const jobId = uuid.v4().toString();
        Geolocation.getCurrentPosition(
            (info) => {
                const location = `${info.coords.latitude},${info.coords.longitude}`;
                dispatch(AnalyseAction.initiateUpload.request({
                    jobId: jobId,
                    isBase64: false,
                    dataObject: data?.assets[0],
                    source: source,
                    location: location
                }));
            },
            (error) => {
                console.log('Error occurred while fetching location', error)
                dispatch(AnalyseAction.initiateUpload.request({
                    jobId: jobId,
                    isBase64: false,
                    dataObject: data?.assets[0],
                    source: source,
                    location: ""
                }));
            });
    }

    const setResponseAndUploadDoubleSidedFile = async (data: any, source = "image") => {
        if (data?.assets === undefined ||
            data?.assets === null ||
            data?.assets.length === 0
            || data?.assets.length === 1) {
            return
        }
        setWaiting(true)
        setTimeout(() => {
            setWaiting(false)
        }, UPLOAD_TIMEOUT)
        const jobId = uuid.v4().toString();
        Geolocation.getCurrentPosition(
            (info) => {
                const location = `${info.coords.latitude},${info.coords.longitude}`;
                dispatch(AnalyseAction.initiateDoubleSidedUpload.request({
                    jobId: jobId,
                    isBase64: false,
                    dataObject: data?.assets,
                    source: source,
                    location: location
                }));
            },
            (error) => {
                console.log('Error occurred while fetching location', error)
                dispatch(AnalyseAction.initiateDoubleSidedUpload.request({
                    jobId: jobId,
                    isBase64: false,
                    dataObject: data?.assets,
                    source: source,
                    location: ""
                }));
            });

    }

    const checkStatusAndStartScan = async () => {
        setWaiting(true)
        setTimeout(() => {
            console.log('set waiting false')
            setWaiting(false)
        }, SCAN_TIMEOUT)

        const jobId = uuid.v4().toString();
        Geolocation.getCurrentPosition(
            (info) => {
                const location = `${info.coords.latitude},${info.coords.longitude}`;
                dispatch(AnalyseAction.initiateScan.request({
                    jobId: jobId,
                    source: "image",
                    location: location
                }));
            },
            (error) => {
                console.log('Error occurred while fetching location', error)
                dispatch(AnalyseAction.initiateScan.request({
                    jobId: jobId,
                    source: "image",
                    location: ""
                }));
            });

    }

    const displayScanError = (errorType: string) => {
        if (errorType === JobStatus.WIFI_NOT_CONNECTED) {
            setDialogVisible(true);
            setDialogStatusMessage("Wifi is not connected on the GRAMS IoT device. Please connect it to a Wifi network and try again.");
        } else if (errorType === JobStatus.SCANNER_NOT_READY) {
            setDialogVisible(true);
            setDialogStatusMessage("Scanner is not ready. Please check if it is connected and powered on.");
        } else if (errorType === JobStatus.IOT_NOT_CONNECTED) {
            setDialogVisible(true);
            setDialogStatusMessage("GRAMS IoT device is not connected. Please connect it to your computer and try again.");
        } else if (errorType === JobStatus.JOB_IMAGE_SCAN_ERROR) {
            setDialogVisible(true);
            setDialogStatusMessage("Error occured while scanning image. Please check scanner and try again.");
        }
    }

    const getProgressBarMessage = () => {
        if (jobStatus === JobStatus.JOB_IMAGE_SCANNING) {
            return 'Scanning image...';
        } else if (
            jobStatus === JobStatus.JOB_IMAGE_UPLOADING) {
            return 'Uploading image...';
        } else if (jobStatus === JobStatus.JOB_CREATING_MANUAL_ENTRY) {
            return 'Processing...';
        }
        else {
            return "Uploading image..."
        }
    }

    const isProgressBarVisible = () => {
        const waitingForResult = isImageScanningOrUploading(jobStatus);

        if (waitingForResult && waiting) {
            return true
        }
        return false
    }

    const imageUploadButtonClick = () => {
        if (isProgressBarVisible()) {
            console.log('Scan or upload in progress')
            return
        }
        launchImageLibrary({
            selectionLimit: 0,
            mediaType: 'photo',
            includeBase64: false,
        }, (data) => checkAuthAndUpload(data, "image"))
    }

    const doubleSidedImageUploadButtonClick = () => {
        if (isProgressBarVisible()) {
            console.log('Scan or upload in progress')
            return
        }
        launchImageLibrary({
            selectionLimit: 2,
            mediaType: 'photo',
            includeBase64: false,
        }, (data) => checkAuthAndDoubleSidedUpload(data, "image"))
    }

    const imageCaptureButtonClick = () => {
        if (isProgressBarVisible()) {
            console.log('Scan or upload in progress')
            return
        }
        launchCamera({
            mediaType: 'photo',
            includeBase64: false,
        }, (data) => checkAuthAndUpload(data, "camera"))
    }

    const manualEntryButtonClicked = async (payload: IManualJobEntryInput) => {
        if (isProgressBarVisible()) {
            console.log('Scan or upload in progress')
            return
        }

        await checkAuthAndManualUpload(payload);
    }

    return (
        <Box variant={"main"} style={[containerStyle.mainContainer, {
            height: '100%',
        }]}>
            <CustomAlertDialog
                title='Grams IoT'
                description={dialogStatusMessage}
                cancelText='Cancel'
                confirmText='Continue'
                isOpen={dialogVisible}
                onCancelClicked={hideDialog}
                onConfirmClicked={() => {
                    hideDialog();
                    navigation.push('ConnectToRpi', {
                        returnBackTo: 'Home',
                    })
                }}
            />
            <InfoStatusBar navigation={navigation} />
            <Box style={{
                marginTop: 20,
            }}>
                {
                    isProgressBarVisible() &&
                    <ProgressBar
                        isVisibile={isProgressBarVisible()}
                        withoutOverlay={true}
                        progressText={getProgressBarMessage()} />
                }
            </Box>
            <MeasurementInfo />
            <FabButtons
                scanImageClicked={async () => await checkAuthAndScan()}
                imageUploadClicked={imageUploadButtonClick}
                captureImageClicked={imageCaptureButtonClick}
                doubleSidedImageUploadClicked={doubleSidedImageUploadButtonClick}
                manualEntryClicked={async (payload: IManualJobEntryInput) => {
                    await manualEntryButtonClicked(payload)
                }}
            />
        </Box>
    );
}