import { Box, Select, Text } from 'native-base';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import * as ProductReleaseAction from '../../redux/actions/productReleaseAction';
import DisplayCsvTableComponent from 'src/components/DisplayCsvTableComponent';
import { containerStyle } from 'src/styles/containerStyle';
import { textStyle } from 'src/styles/textStyle';

export default function ProductReleasePage() {

    const dispatch = useDispatch();
    const productReleases = useSelector((store: IStore) => store.productRelease.productReleases);

    const [selectedProduct, setSelectedProduct] = useState('m10');
    const [productReleaseData, setProductReleaseData] = useState<any[]>([]);

    const [columnsToDisplay, setColumnsToDisplay] = useState<number[]>([0, 1, 2, 3]);
    
    const handleProductChange = (itemValue: string) => {
        setSelectedProduct(itemValue);
        dispatch(ProductReleaseAction.getProductReleases.request(itemValue));
    }

    useEffect(() => {
        dispatch(ProductReleaseAction.getProductReleases.request(selectedProduct));
    }, []);

    useEffect(() => {
        if (productReleases?.response && productReleases?.response?.length > 0) {
            let sortedProductReleaseData: any[] = productReleases?.response?.map((productRelease) => {

                const urlText = selectedProduct === 'web' ?
                    <a href={productRelease.url} target='_blank'>Go to Web App</a>
                    : selectedProduct === 'm10' ? <a target='_blank' href={productRelease.url} >Download app</a>
                        : <a target='_blank' href={productRelease.url} >Download installer</a>

                return [
                    productRelease?.releaseId.substring(0, 8),
                    new Date(productRelease?.createdAt * 1000).toLocaleString(),
                    productRelease?.version,
                    urlText
                ]
            });

            sortedProductReleaseData.sort((a, b) => {
                const dateA = new Date(a[1]);
                const dateB = new Date(b[1]);
                return dateB.getTime() - dateA.getTime();
            });

            if (selectedProduct === 'web' || selectedProduct === 'm10') {
                setColumnsToDisplay([0, 1, 2]);
            } else {
                setColumnsToDisplay([0, 1, 2, 3]);
            }
            setProductReleaseData(sortedProductReleaseData);
        }
    }, [productReleases]);

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <Select
                selectedValue={selectedProduct}
                onValueChange={itemValue => handleProductChange(itemValue)}>
                <Select.Item label="M10" value="m10" />
                <Select.Item label="W10" value="w10" />
                <Select.Item label="Web" value="web" />
            </Select>

            <Box>
                <Text style={textStyle.subSectionText}>Latest Release Information</Text>
                <Text>Latest Release: {productReleaseData?.length > 0 ? `Version: ${productReleaseData[0][2]} on ${productReleaseData[0][1]}` : "N/A"}</Text>
                <Text>Get it from: {productReleaseData?.length > 0 ? productReleaseData[0][3] : "N/A"}</Text>
            </Box>

            <DisplayCsvTableComponent
                title="Product Release History"
                columns={columnsToDisplay}
                tableHead={["Release Id", "Release Date", "Version", "URL"]}
                tableData={productReleaseData} />
        </Box>
    );
}