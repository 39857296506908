import * as Effects from "redux-saga/effects";

import * as Organization from "../actions/orgAction";
import * as api from "../../api";
import { showErrorMessage } from "./shared";
import { IStore } from "../reducers";
import { ICenter } from "src/models/Center";
import { IOrgStatistics, IOrgStatisticsRequest } from "src/models/UserStatistics";
import { getCacheExpiryDuration } from "src/utils/remoteConfig";

const call: any = Effects.call;
const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;
const select: any = Effects.select;

export const getCachedCenters = (state: IStore) => state.org.centers.centers;
export const getExpiry = (store: IStore) => store.org.expiry;

export function* centers(action: ReturnType<typeof Organization.getCenters.request>): any {
  try {
    let expiry = yield select(getExpiry);
    let expiryDate = expiry === undefined ? undefined : new Date(expiry);
    let currentDate = new Date();
    let cachedCenters = yield select(getCachedCenters);

    if (cachedCenters !== undefined && expiryDate !== undefined
      && expiryDate.getTime() > currentDate.getTime()) {
      yield put(Organization.getCenters.success(cachedCenters))
    } else {
      const response: ICenter[] = yield call(api.getCenters, action.payload);
      yield put(Organization.getCenters.success({
        centers: response,
        expiry: new Date(currentDate.setSeconds(currentDate.getSeconds() + getCacheExpiryDuration())),
        cached: false
      }));
    }
  } catch (error: any) {
    showErrorMessage(error);
    yield put(Organization.getCenters.failure(error));
  }
}

export function* centersWithStats(action: ReturnType<typeof Organization.getCentersWithStats.request>): any {
  try {
    const response: ICenter[] = yield call(api.getCenters, action.payload);
    yield put(Organization.getCentersWithStats.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(Organization.getCentersWithStats.failure(error));
  }
}

export function* fetchCenterWithTrucks(action: ReturnType<typeof Organization.getCenterTrucks.request>): any {
  try {
    const response = yield call(api.getCenterWithStats, action.payload);
    yield put(Organization.getCenterTrucks.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(Organization.getCenterTrucks.failure(error));
  }
}

export function* fetchCenterDistricts(action: ReturnType<typeof Organization.getCenterDistricts.request>): any {
  try {
    const response = yield call(api.getCenterDistricts, action.payload);
    yield put(Organization.getCenterDistricts.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(Organization.getCenterDistricts.failure(error));
  }
}

export function* fetchOrgStatistics(action: ReturnType<typeof Organization.fetchOrgStatistics.request>): any {
  try {
    const payload: IOrgStatisticsRequest = action.payload;
    const response: IOrgStatistics = yield call(api.getOrgStatistics, action.payload);
    yield put(Organization.fetchOrgStatistics.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(Organization.fetchOrgStatistics.failure(error));
  }
}

export function* fetchSurveyors(action: ReturnType<typeof Organization.fetchSurveyors.request>): any {
  try {
    const response = yield call(api.getSurveyors, action.payload);
    yield put(Organization.fetchSurveyors.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(Organization.fetchSurveyors.failure(error));
  }
}

/*
 * WATCHERS
 */

export function* watchCenters() {
  yield takeEvery(Organization.getCenters.request, centers);
}
export function* watchFetchOrgStatistics() {
  yield takeEvery(Organization.fetchOrgStatistics.request, fetchOrgStatistics);
}
export function* watchFetchSurveyors() {
  yield takeEvery(Organization.fetchSurveyors.request, fetchSurveyors);
}

export function* watchFetchCenterWithTrucks() {
  yield takeEvery(Organization.getCenterTrucks.request, fetchCenterWithTrucks);
}

export function* watchFetchCenterDistricts() {
  yield takeEvery(Organization.getCenterDistricts.request, fetchCenterDistricts);
}

export function* watchFetchCentersWithStats() {
  yield takeEvery(Organization.getCentersWithStats.request, centersWithStats);
}

export default function* root() {
  yield all([fork(watchCenters),
  fork(watchFetchOrgStatistics),
  fork(watchFetchCentersWithStats),
  fork(watchFetchCenterWithTrucks),
  fork(watchFetchCenterDistricts),
  fork(watchFetchSurveyors)
  ]);
}
