import { ActionType, getType } from "typesafe-actions";
import { IAllUserSettingsResponse, IUserProfile, IUserProfileResponse, IUserSettingsResponse } from "../../models";
import * as actions from "../actions/userAction";

export interface IUserState {
    subscriptionStatus: string;
    userSettings: { [key: string]: any; };
    readonly userProfile: IGetUserProfileState;
    readonly userActivity: ILogUserActivityState;
    readonly updateUserSettings: IUpdateUserSettingsState;
    readonly updateUserLogo: IUpdateUserLogoState;
}

export interface ILogUserActivityState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response: Boolean;
}

export interface IGetUserProfileState {
    expiry: Date | undefined;
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response: IUserProfile | undefined;
}

export interface IUserSettingsState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response: IUserSettingsResponse | undefined;
}

export interface IAllUserSettingsState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response: IAllUserSettingsResponse | undefined;
}

export interface IUpdateUserLogoState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response: Boolean | undefined;
}

export interface IUpdateUserSettingsState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response: Boolean | undefined;
}

export const initialUserState: IUserState = {
    subscriptionStatus: "ACTIVE",
    userSettings: {},
    userActivity: {
        error: undefined,
        isFetching: false,
        response: false
    },
    updateUserSettings: {
        error: undefined,
        isFetching: false,
        response: undefined
    },
    updateUserLogo: {
        error: undefined,
        isFetching: false,
        response: undefined
    },
    userProfile: {
        expiry: undefined,
        error: undefined,
        isFetching: false,
        response: undefined
    }
};

export const UserReducer = (
    state: IUserState = initialUserState,
    action: ActionType<typeof actions>
): IUserState => {
    switch (action.type) {
        case getType(actions.setUserSubscriptionStatus):
            return {
                ...state,
                subscriptionStatus: action.payload
            };
        case getType(actions.getUserSettings.success):
            let userSettings = Object.assign({}, state.userSettings);
            userSettings[action.payload.key] = action.payload.value;
            return {
                ...state,
                userSettings: userSettings
            };
        case getType(actions.updateUserSettings.request):
            return {
                ...state,
                updateUserSettings: {
                    ...state.updateUserSettings,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.updateUserSettings.success):
            return {
                ...state,
                updateUserSettings: {
                    error: undefined,
                    isFetching: false,
                    response: action.payload
                }
            };
        case getType(actions.updateUserSettings.failure):
            return {
                ...state,
                updateUserSettings: {
                    ...state.updateUserSettings,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.logUserActivity.request):
            return {
                ...state,
                userActivity: {
                    ...state.userActivity,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.logUserActivity.success):
            return {
                ...state,
                userActivity: {
                    error: undefined,
                    isFetching: false,
                    response: action.payload
                }
            };
        case getType(actions.logUserActivity.failure):
            return {
                ...state,
                userActivity: {
                    ...state.userActivity,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.updateUserLogo.request):
            return {
                ...state,
                updateUserLogo: {
                    ...state.updateUserLogo,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.updateUserLogo.success):
            return {
                ...state,
                updateUserLogo: {
                    error: undefined,
                    isFetching: false,
                    response: action.payload
                }
            };
        case getType(actions.updateUserLogo.failure):
            return {
                ...state,
                updateUserLogo: {
                    ...state.updateUserLogo,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.getUserProfile.request):
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.getUserProfile.success):
            var resp = action.payload as IUserProfileResponse;
            if (resp.cached) {
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        error: undefined,
                        isFetching: false,
                        response: resp.profile
                    }
                };
            } else {
                return {
                    ...state,
                    userProfile: {
                        expiry: resp.expiry,
                        error: undefined,
                        isFetching: false,
                        response: resp.profile
                    }
                };
            }
        case getType(actions.getUserProfile.failure):
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.reset):
            return initialUserState;
        default:
            return state;
    }
};