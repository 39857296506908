import * as Effects from "redux-saga/effects";

import * as AnalyseAction from "../../actions/analyseAction";
import * as UploadAction from "../../actions/uploadAction";
import * as JobAction from "../../actions/jobAction";
import windowsScan from "../../../services/WindowsScan";
import { IStore } from "../../reducers";
import { JobStatus } from "../../../models/JobStatus";
import { IAnalyseDoubleSidedUploadRequest, IAnalyseManualJobRequest, IAnalyseRetrialRequest, IAnalyseScanRequest, IAnalyseUploadRequest } from "../../../models";
import uuid from 'react-native-uuid';

const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;
const select: any = Effects.select;
const call: any = Effects.call;

export const getGrainType = (store: IStore) => store.grainType.grainId;

export function* initiateScan(action: ReturnType<typeof AnalyseAction.initiateScan.request>): any {
    const request: IAnalyseScanRequest = action.payload;
    try {
        console.log('web initiate scan')
        yield put(JobAction.setJobId(request.jobId));
        yield put(JobAction.updateRpiJobStatus({
            jobId: request.jobId,
            status: JobStatus.JOB_IMAGE_SCANNING,
        }));
        const imageData = yield call(windowsScan.scanImageWindows);
        yield put(UploadAction.upload.request({
            jobId: request.jobId,
            isBase64: true,
            dataObject: imageData,
            source: 'image',
            location: request.location
        }))
    } catch (error: any) {
        console.log(error)
        yield put(JobAction.updateRpiJobStatus({
            jobId: request.jobId,
            status: JobStatus.JOB_IMAGE_SCAN_ERROR,
        }));
        yield put(AnalyseAction.initiateScan.failure());
    }
}

export function* initiateUpload(action: ReturnType<typeof AnalyseAction.initiateUpload.request>): any {
    try {
        const request: IAnalyseUploadRequest = action.payload;
        yield put(JobAction.setJobId(request.jobId));
        yield put(UploadAction.upload.request({
            isBase64: false,
            dataObject: request.dataObject,
            jobId: request.jobId,
            source: request.source,
            location: request.location
        }));
    } catch (error: any) {
        yield put(AnalyseAction.initiateUpload.failure());
    }
}

export function* initiateDoubleSidedUpload(action: ReturnType<typeof AnalyseAction.initiateDoubleSidedUpload.request>): any {
    try {
        const request: IAnalyseDoubleSidedUploadRequest = action.payload;
        yield put(JobAction.setJobId(request.jobId));
        yield put(UploadAction.uploadDoubleSided.request({
            isBase64: false,
            dataObject: request.dataObject,
            jobId: request.jobId,
            source: request.source,
            location: request.location
        }));
    } catch (error: any) {
        yield put(AnalyseAction.initiateDoubleSidedUpload.failure());
    }
}

export function* initiateManualJob(action: ReturnType<typeof AnalyseAction.initiateManualJob.request>): any {
    try {
        const request: IAnalyseManualJobRequest = action.payload;
        yield put(JobAction.setJobId(request.jobId));

        yield put(JobAction.updateRpiJobStatus({
            jobId: request.jobId,
            status: JobStatus.JOB_CREATING_MANUAL_ENTRY,
        }));

        yield put(JobAction.createJob.request({
            jobId: request.jobId,
            source: 'manual',
            manualEntryInput: request.manualJobEntryInput,
            location: request.location
        }))
    } catch (error: any) {
        yield put(AnalyseAction.initiateManualJob.failure());
    }
}

export function* initiateTestJob(action: ReturnType<typeof AnalyseAction.initiateTestJob.request>): any {
    try {
        const grainId = yield select(getGrainType);
        const jobId = uuid.v4().toString();
        yield put(JobAction.setJobId(jobId));
        yield put(JobAction.createJob.request({
            jobId: jobId,
            test: true,
            object_key: `testImages/${grainId}/1.jpg`,
            source: 'image',
            location: ''
        }))
        yield put(JobAction.queueJob.request({
            jobId: jobId
        }))
    } catch (error: any) {
        yield put(AnalyseAction.initiateUpload.failure());
    }
}

export function* initiateRetrial(action: ReturnType<typeof AnalyseAction.initiateRetrial.request>): any {
    try {
        const request: IAnalyseRetrialRequest = action.payload;
        yield put(JobAction.queueJob.request({
            jobId: request.jobId
        }))
    } catch (error: any) {
        yield put(AnalyseAction.initiateUpload.failure());
    }
}

/*
* WATCHERS
*/

export function* watchInitiateScan() {
    yield takeEvery(AnalyseAction.initiateScan.request, initiateScan);
}

export function* watchInitiateUpload() {
    yield takeEvery(AnalyseAction.initiateUpload.request, initiateUpload);
}

export function* watchInitiateDoubleSidedUpload() {
    yield takeEvery(AnalyseAction.initiateDoubleSidedUpload.request, initiateDoubleSidedUpload);
}

export function* watchInitiateManualJob() {
    yield takeEvery(AnalyseAction.initiateManualJob.request, initiateManualJob);
}

export function* watchInitiateTestJob() {
    yield takeEvery(AnalyseAction.initiateTestJob.request, initiateTestJob);
}

export function* watchInitiateRetrial() {
    yield takeEvery(AnalyseAction.initiateRetrial.request, initiateRetrial);
}

export default function* root() {
    yield all([fork(watchInitiateScan),
    fork(watchInitiateUpload),
    fork(watchInitiateDoubleSidedUpload),
    fork(watchInitiateManualJob),
    fork(watchInitiateRetrial),
    fork(watchInitiateTestJob)]);
}