import { ActionType, getType } from 'typesafe-actions';
import { FaqItem } from 'src/models/FaqItem';
import * as actions from '../actions/userGuideActions';

export interface IFaqItemsState {
  readonly isFetching: boolean;
  readonly items: FaqItem[];
  readonly error?: Error;
}

export interface IUserGuideState {
  readonly faqItems: IFaqItemsState;
}

export const initialFaqItemsState: IFaqItemsState = {
  isFetching: false,
  items: [],
  error: undefined,
};

export const initialUserGuideState: IUserGuideState = {
  faqItems: initialFaqItemsState,
};

export const UserGuideReducer = (
  state: IUserGuideState = initialUserGuideState,
  action: ActionType<typeof actions>
): IUserGuideState => {
  switch (action.type) {
    case getType(actions.getFaqItems.request):
      return {
        ...state,
        faqItems: {
          ...state.faqItems,
          isFetching: true,
          error: undefined,
        },
      };
    case getType(actions.getFaqItems.success):
      return {
        ...state,
        faqItems: {
          ...state.faqItems,
          isFetching: false,
          items: action.payload,
        },
      };
    case getType(actions.getFaqItems.failure):
      return {
        ...state,
        faqItems: {
          ...state.faqItems,
          isFetching: false,
          error: action.payload,
        },
      };
    case getType(actions.resetUserGuide):
      return initialUserGuideState;
    default:
      return state;
  }
};
