import { ActionType, getType } from "typesafe-actions";
import { IReportResponse } from "../../models";
import * as actions from "../actions/reportAction";

export interface IReportState {
    readonly jobReport: IJobReportState;
}

export interface IJobReportState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response?: IReportResponse | null;
}

export const initialReportState: IReportState = {
    jobReport: {
        error: undefined,
        isFetching: false,
        response: undefined
    }
};

export const ReportReducer = (
    state: IReportState = initialReportState,
    action: ActionType<typeof actions>
): IReportState => {
    switch (action.type) {
        case getType(actions.getJobReport.request):
            return {
                ...state,
                jobReport: {
                    ...state.jobReport,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.getJobReport.success):
            return {
                ...state,
                jobReport: {
                    error: undefined,
                    isFetching: false,
                    response: action.payload
                }
            };
        case getType(actions.getJobReport.failure):
            return {
                ...state,
                jobReport: {
                    ...state.jobReport,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.reset):
            return initialReportState;
        default:
            return state;
    }
};