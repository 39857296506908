import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { useDispatch } from 'react-redux';
import * as LoginActions from '../../redux/actions/loginAction';
import { validateEmail, validatePhoneNumber } from 'src/utils/utils';
import { Box, Button, FormControl, Icon, IconButton, Input, VStack } from 'native-base';
import { containerStyle } from 'src/styles/containerStyle';
import { inputStyle } from 'src/styles/inputStyle';
import ProgressBar from 'src/components/ProgressBar';
import InweonLogo from 'src/components/InweonLogo';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { formProps } from 'src/styles/formProps';

interface SignupFormState {
    username: string,
    password: string,
    email: string,
    phoneNumber: string
}

export default function SignupPage({ navigation }) {

    const [securePasswordEntry, setSecurePasswordEntry] = React.useState(true);

    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [formData, setData] = React.useState<SignupFormState>({
        username: '',
        password: '',
        email: '',
        phoneNumber: ''
    });

    const [errors, setErrors] = React.useState({});

    const validate = () => {
        if (formData.username === undefined
            || formData.username.length === 0) {
            setErrors({
                username: 'Username is required'
            });
            return false;
        } else if (formData.password === undefined
            || formData.password.length === 0) {
            console.log('password is required')
            setErrors({
                password: 'Password is required'
            });
            return false;
        } else if (formData.password.length < 8) {
            setErrors({
                password: 'Password must be at least 8 characters'
            });
            return false;
        } else if (formData.email === undefined
            || formData.email.length === 0) {
            setErrors({
                email: 'Email is required'
            });
            return false;
        } else if (!validateEmail(formData.email)) {
            setErrors({
                email: 'Email is not valid'
            });
            return false;
        } else if (formData.phoneNumber === undefined
            || formData.phoneNumber.length === 0) {
            setErrors({
                phoneNumber: 'Phone number is required'
            });
            return false;
        } else if (!validatePhoneNumber(formData.phoneNumber)) {
            setErrors({
                phoneNumber: 'Phone number is not valid'
            });
            return false;
        }

        return true;
    };

    const dispatch = useDispatch();

    const handleSignup = async () => {
        setShowLoadingIndicator(true)
        try {
            navigation.navigate('ConfirmSignupPage', {
                "username": formData.username,
                "password": formData.password,
            })
        } catch (ex: any) {
            setErrors({
                formError: ex.message
            });
            setShowLoadingIndicator(false)
            dispatch(LoginActions.setUserLoggedIn("NOT_LOGGED_IN"));
        }
    }

    const handleAccountExists = async () => {
        navigation.navigate('LoginPage')
    }

    const onSubmit = () => {
        const isValid = validate();
        if (isValid) {
            // submit the data
            handleSignup()
        }
    };

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isVisibile={showLoadingIndicator} progressText={"Creating your account..."} />
            <ScrollView automaticallyAdjustKeyboardInsets={true}>
                <InweonLogo title='Sign up for a GRAMS account' />
                <VStack width={formProps.width}
                    style={containerStyle.formContainer} mx="3">
                    <FormControl isRequired>
                        <FormControl.Label>Username</FormControl.Label>
                        <Input
                            style={inputStyle.nativeInput}
                            isInvalid={'username' in errors}
                            placeholder="username"
                            onChangeText={value => setData({
                                ...formData,
                                username: value
                            })} />
                        {'username' in errors ? <FormControl.ErrorMessage>{errors['username']}</FormControl.ErrorMessage> : null}

                        <FormControl.Label>Password</FormControl.Label>
                        <Input
                            secureTextEntry={securePasswordEntry}
                            style={inputStyle.nativeInput}
                            isInvalid={'password' in errors}
                            InputRightElement={
                                <IconButton
                                    onPress={() => {
                                        setSecurePasswordEntry(!securePasswordEntry)
                                    }}
                                    icon={<MaterialCommunityIcons name="eye" />} />
                            }
                            placeholder="********"
                            onChangeText={value => setData({
                                ...formData,
                                password: value
                            })} />
                        {'password' in errors ? <FormControl.ErrorMessage>{errors['password']}</FormControl.ErrorMessage> : null}

                        <FormControl.Label>Email</FormControl.Label>
                        <Input
                            style={inputStyle.nativeInput}
                            isInvalid={'email' in errors}
                            placeholder="user@domain.com"
                            onChangeText={value => setData({
                                ...formData,
                                email: value
                            })} />
                        {'email' in errors ? <FormControl.ErrorMessage>{errors['email']}</FormControl.ErrorMessage> : null}

                        <FormControl.Label>Phone number</FormControl.Label>

                        <Input
                            style={inputStyle.nativeInput}
                            isInvalid={'phoneNumber' in errors}
                            placeholder="9999xxxxxx"
                            onSubmitEditing={onSubmit}
                            onChangeText={value => setData({
                                ...formData,
                                phoneNumber: value
                            })} />
                        {'phoneNumber' in errors ? <FormControl.ErrorMessage>{errors['phoneNumber']}</FormControl.ErrorMessage> : null}
                    </FormControl>
                    <Button onPress={onSubmit} mt="5">
                        Submit
                    </Button>
                    {'formError' in errors ? <Text color="red.500" mt="2">{errors['formError'] || ""}</Text> : null}
                </VStack>

                <Button variant={"link"} color={'#000'} onPress={handleAccountExists}>
                    Already have an account?
                </Button>
            </ScrollView>
        </Box>
    );
}