import { createAction } from "typesafe-actions";
import { IGrainProfile, IGrainProfileMapping } from "../../models";

export const setProfileId = createAction('SET_PROFILE_ID')<string>();

export const setGrainProfiles = createAction('SET_GRAIN_PROFILES')<IGrainProfile[]>();

export const setGrainProfileMappings = createAction('SET_GRAIN_PROFILE_MAPPINGS')<IGrainProfileMapping[]>();

export const setSelectedGrainProfileMapping = createAction('SET_SELECTED_GRAIN_PROFILE_MAPPING')<IGrainProfileMapping>();

export const reset = createAction('RESET')<void>();