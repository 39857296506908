import { ActionType, getType } from "typesafe-actions";
import { IProductRelease } from "../../models";
import * as actions from "../actions/productReleaseAction";

export interface IProductReleaseState {
    readonly productReleases: IGetProductReleasesState;
}

export interface IGetProductReleasesState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response?: IProductRelease[] | null;
}

export const initialProductReleaseState: IProductReleaseState = {
    productReleases: {
        error: undefined,
        isFetching: false,
        response: undefined
    }
};

export const ProductReleaseReducer = (
    state: IProductReleaseState = initialProductReleaseState,
    action: ActionType<typeof actions>
): IProductReleaseState => {
    switch (action.type) {
        case getType(actions.getProductReleases.request):
            return {
                ...state,
                productReleases: {
                    ...state.productReleases,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.getProductReleases.success):
            return {
                ...state,
                productReleases: {
                    error: undefined,
                    isFetching: false,
                    response: action.payload
                }
            };
        case getType(actions.getProductReleases.failure):
            return {
                ...state,
                productReleases: {
                    ...state.productReleases,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.reset):
            return initialProductReleaseState;
        default:
            return state;
    }
};