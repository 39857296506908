import * as React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import ProgressBar from 'src/components/ProgressBar';
import { containerStyle } from 'src/styles/containerStyle';
import { Box } from 'native-base';
import UserJobHistoryList from 'src/components/list/UserJobHistoryList';
import HomeStatusBar from 'src/components/HomeStatusBar';
import FilterModal from 'src/components/modal/FilterModal';

export default function UserJobsPage({ navigation }) {

    const isFetching = useSelector((store: IStore) => store.job.orgJobs.isFetching);

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <HomeStatusBar />
            <FilterModal />
            <ProgressBar isVisibile={isFetching} progressText="Loading..." />
            <UserJobHistoryList pageType='single' navigation={navigation} />
        </Box>
    );
}