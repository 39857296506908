import { ActionType, getType } from "typesafe-actions";
import { IBluetoothPeripheral, IRpiStatus } from "../../models";
import * as actions from "../actions/bluetoothAction";

export interface IBluetoothState {
    discoveredDevices: IBluetoothPeripheral[];
    readonly connectedRpi: IConnectedDeviceState;
    readonly disconnectRpi: IDisconnectDeviceState;
    readonly connectedMoistureDevice: IConnectedDeviceState;
    readonly scanImage: IScanImageState;
    readonly uploadBleLogs: IUploadBleLogsState;
    readonly configureWifi: IConfigureRpiWifiState;
    readonly rpiStatus: IRpiStatusState;
    readonly subscribeRpiStatus: ISubscribeRpiState;
    wifiConfigChanged: boolean;
}

export interface IConnectedDeviceState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly connectedDevice: String | undefined;
}

export interface IDisconnectDeviceState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly isDisconnected: boolean;
}

export interface IScanImageState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly scanStatus: boolean;
}

export interface IUploadBleLogsState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly uploadStatus: boolean;
}

export interface IConfigureRpiWifiState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly configured: boolean;
}

export interface ISubscribeRpiState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly subscribed: boolean;
}

export interface IRpiStatusState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly status: IRpiStatus | undefined;
}

export const initialBluetoothState: IBluetoothState = {
    discoveredDevices: [],
    connectedRpi: {
        error: undefined,
        isFetching: false,
        connectedDevice: undefined
    },
    disconnectRpi: {
        error: undefined,
        isFetching: false,
        isDisconnected: false,
    },
    wifiConfigChanged: false,
    connectedMoistureDevice: {
        error: undefined,
        isFetching: false,
        connectedDevice: undefined
    },
    scanImage: {
        error: undefined,
        isFetching: false,
        scanStatus: false
    },
    configureWifi: {
        error: undefined,
        isFetching: false,
        configured: false
    },
    rpiStatus: {
        error: undefined,
        isFetching: false,
        status: undefined
    },
    subscribeRpiStatus: {
        error: undefined,
        isFetching: false,
        subscribed: false
    }
};

export const BluetoothReducer = (
    state: IBluetoothState = initialBluetoothState,
    action: ActionType<typeof actions>
): IBluetoothState => {
    switch (action.type) {
        case getType(actions.deviceDiscovered):
            var allDevices = state.discoveredDevices;

            if (allDevices.filter(device => device.id === action.payload.id).length == 0
                && (action.payload.name == "Raspberry Pi" || action.payload.name == "GRAMS App")) {
                allDevices.push(action.payload);
                console.log('allDevices', allDevices);
            }

            allDevices = allDevices.sort((a, b) => {
                return b.name < a.name ? -1 : 1;
            })

            return {
                ...state,
                discoveredDevices: allDevices
            };
        case getType(actions.connectToRpiDevice.request):
            return {
                ...state,
                connectedRpi: {
                    error: undefined,
                    isFetching: true,
                    connectedDevice: undefined
                }
            };
        case getType(actions.connectToRpiDevice.success):
            return {
                ...state,
                connectedRpi: {
                    error: undefined,
                    isFetching: false,
                    connectedDevice: action.payload
                }
            };
        case getType(actions.connectToRpiDevice.failure):
            return {
                ...state,
                connectedRpi: {
                    error: undefined,
                    isFetching: false,
                    connectedDevice: undefined
                }
            };
        case getType(actions.disconnectFromRpiDevice.request):
            return {
                ...state,
                disconnectRpi: {
                    ...state.disconnectRpi,
                    error: undefined,
                    isFetching: true,
                }
            };
        case getType(actions.disconnectFromRpiDevice.success):
            return {
                ...state,
                connectedRpi: {
                    error: undefined,
                    isFetching: false,
                    connectedDevice: undefined
                },
                disconnectRpi: {
                    isDisconnected: true,
                    error: undefined,
                    isFetching: false,
                }
            };
        case getType(actions.disconnectFromRpiDevice.failure):
            return {
                ...state,
                disconnectRpi: {
                    isDisconnected: false,
                    error: undefined,
                    isFetching: false,
                }
            };
        case getType(actions.connectToMoistureDevice.request):
            return {
                ...state,
                connectedMoistureDevice: {
                    error: undefined,
                    isFetching: true,
                    connectedDevice: undefined
                }
            };
        case getType(actions.connectToMoistureDevice.success):
            return {
                ...state,
                connectedMoistureDevice: {
                    error: undefined,
                    isFetching: true,
                    connectedDevice: action.payload
                }
            };
        case getType(actions.connectToMoistureDevice.failure):
            return {
                ...state,
                connectedMoistureDevice: {
                    error: undefined,
                    isFetching: false,
                    connectedDevice: undefined
                }
            };
        case getType(actions.scanImageUsingRpi.request):
            return {
                ...state,
                scanImage: {
                    error: undefined,
                    isFetching: true,
                    scanStatus: false
                }
            };
        case getType(actions.scanImageUsingRpi.success):
            return {
                ...state,
                scanImage: {
                    error: undefined,
                    isFetching: false,
                    scanStatus: action.payload
                }
            };
        case getType(actions.scanImageUsingRpi.failure):
            return {
                ...state,
                scanImage: {
                    error: undefined,
                    isFetching: false,
                    scanStatus: false
                }
            };
        case getType(actions.configueRpiWifi.request):
            return {
                ...state,
                configureWifi: {
                    error: undefined,
                    isFetching: true,
                    configured: false
                }
            };
        case getType(actions.configueRpiWifi.success):
            return {
                ...state,
                configureWifi: {
                    error: undefined,
                    isFetching: false,
                    configured: action.payload
                }
            };
        case getType(actions.configueRpiWifi.failure):
            return {
                ...state,
                configureWifi: {
                    error: undefined,
                    isFetching: false,
                    configured: false
                }
            };
        case getType(actions.getRpiStatus.request):
            return {
                ...state,
                rpiStatus: {
                    ...state.rpiStatus,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.getRpiStatus.success):
            return {
                ...state,
                rpiStatus: {
                    error: undefined,
                    isFetching: false,
                    status: action.payload
                }
            };
        case getType(actions.getRpiStatus.failure):
            return {
                ...state,
                rpiStatus: {
                    ...state.rpiStatus,
                    error: undefined,
                    isFetching: false
                }
            };
        case getType(actions.wifiConfigurationChanged):
            return {
                ...state,
                wifiConfigChanged: action.payload
            };
        case getType(actions.uploadBleLogs.request):
            return {
                ...state,
                uploadBleLogs: {
                    error: undefined,
                    isFetching: true,
                    uploadStatus: false
                }
            };
        case getType(actions.uploadBleLogs.success):
            return {
                ...state,
                uploadBleLogs: {
                    error: undefined,
                    isFetching: false,
                    uploadStatus: action.payload
                }
            };
        case getType(actions.uploadBleLogs.failure):
            return {
                ...state,
                uploadBleLogs: {
                    error: undefined,
                    isFetching: false,
                    uploadStatus: false
                }
            };
        case getType(actions.reset):
            return initialBluetoothState;
        default:
            return state;
    }
};