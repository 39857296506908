import { ActionType, getType } from "typesafe-actions";
import { IGrainTypes } from "../../models";
import * as actions from "../actions/grainTypeAction";

export interface IGrainTypeState {
    grainId?: string;
    grainName?: string;
}

export interface IGrainTypesState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly grainTypes?: IGrainTypes | null;
}

export const initialGrainTypeState: IGrainTypeState = {
    grainId: undefined,
    grainName: undefined,
};

export const GrainTypeReducer = (
    state: IGrainTypeState = initialGrainTypeState,
    action: ActionType<typeof actions>
): IGrainTypeState => {
    switch (action.type) {
        case getType(actions.setGrainId):
            return {
                ...state,
                grainId: action.payload
            };
        case getType(actions.reset):
            return initialGrainTypeState;
        default:
            return state;
    }
};