import { Box } from 'native-base';
import * as React from 'react';
import UserJobHistoryList from 'src/components/list/UserJobHistoryList';
import FilterModal from 'src/components/modal/FilterModal';
import { containerStyle } from 'src/styles/containerStyle';

export default function ReadingsPage({ navigation }) {
    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <FilterModal />
            <UserJobHistoryList pageType='tabs' navigation={navigation} />
        </Box>
    );
}