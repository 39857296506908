import { IDateRange, IJobListTimestampRange, ISearchQuery } from "src/models";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions/appAction";

export interface IAppActionState {
    grainProfileModalIsVisible: boolean;
    currentStack: string;
    searchQuery: ISearchQuery | undefined;
    dateRange: IDateRange | undefined;
    grainIdFilter: string | undefined;
    centerIdFilter: string | undefined;
    stateFilter: string | undefined;
    districtFilter: string | undefined;
    userJobsCachedRange: IJobListTimestampRange | undefined;
    orgJobsCachedRange: IJobListTimestampRange | undefined;
    showForceLogoutPopup: boolean;
    colorMode: string;
}

export const initialAppState: IAppActionState = {
    grainProfileModalIsVisible: false,
    currentStack: 'stackChooser',
    searchQuery: undefined,
    dateRange: undefined,
    grainIdFilter: "all",
    centerIdFilter: "all",
    stateFilter: "all",
    districtFilter: "all",
    userJobsCachedRange: undefined,
    orgJobsCachedRange: undefined,
    showForceLogoutPopup: false,
    colorMode: 'light'
};

export const AppReducer = (
    state: IAppActionState = initialAppState,
    action: ActionType<typeof actions>
): IAppActionState => {
    switch (action.type) {
        case getType(actions.setGrainProfileModalVisible):
            return {
                ...state,
                grainProfileModalIsVisible: action.payload
            };
        case getType(actions.setSearchQuery):
            return {
                ...state,
                searchQuery: action.payload
            };
        case getType(actions.setCurrentStack):
            return {
                ...state,
                currentStack: action.payload
            };
        case getType(actions.setDateRange):
            return {
                ...state,
                dateRange: action.payload
            };
        case getType(actions.setGrainIdFilter):
            return {
                ...state,
                grainIdFilter: action.payload
            };
        case getType(actions.setCenterIdFilter):
            return {
                ...state,
                centerIdFilter: action.payload
            };
        case getType(actions.setDistrictFilter):
            return {
                ...state,
                districtFilter: action.payload
            };
        case getType(actions.setStateFilter):
            return {
                ...state,
                stateFilter: action.payload
            };
        case getType(actions.setAppColorMode):
            return {
                ...state,
                colorMode: action.payload
            };
        case getType(actions.setUserJobsCachedTimestampRange):
            const newUserFrom = state.userJobsCachedRange == undefined ? action.payload.from
                : (action.payload.from < state.userJobsCachedRange.from ? action.payload.from : state.userJobsCachedRange.from)
            const newUserTo = state.userJobsCachedRange == undefined ? action.payload.to
                : (action.payload.to > state.userJobsCachedRange.to ? action.payload.to : state.userJobsCachedRange.to)
            // console.log('setting new range', newUserFrom, newUserTo)
            return {
                ...state,
                userJobsCachedRange: {
                    'from': newUserFrom,
                    'to': newUserTo
                }
            };

        case getType(actions.setOrgJobsCachedTimestampRange):
            const newOrgFrom = state.orgJobsCachedRange == undefined ? action.payload.from
                : (action.payload.from < state.orgJobsCachedRange.from ? action.payload.from : state.orgJobsCachedRange.from)
            const newOrgTo = state.orgJobsCachedRange == undefined ? action.payload.to
                : (action.payload.to > state.orgJobsCachedRange.to ? action.payload.to : state.orgJobsCachedRange.to)
            return {
                ...state,
                orgJobsCachedRange: {
                    'from': newOrgFrom,
                    'to': newOrgTo
                }
            };

        case getType(actions.forceLogout):
            return {
                ...state,
                showForceLogoutPopup: true
            };
        case getType(actions.forceLogoutHandled):
            return {
                ...state,
                showForceLogoutPopup: false
            };
        case getType(actions.reset):
            return initialAppState;
        default:
            return state;
    }
};