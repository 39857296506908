import React, { useEffect } from 'react';
import ConfigureRpiWifi from "../../components/ConfigureRpiWifi";
import { useSelector } from "react-redux";
import { Box, Button } from "native-base";
import ProgressBar from 'src/components/ProgressBar';
import { containerStyle } from 'src/styles/containerStyle';
import { buttonStyle } from 'src/styles/buttonStyle';

export default ConfigureRpiWifiPage = ({ navigation }) => {

    const isFetchingConfigureWifi = useSelector((store) => store.bluetooth.configureWifi.isFetching);

    useEffect(() => {
    }, [isFetchingConfigureWifi]);

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isFetching={isFetchingConfigureWifi} withoutOverlay={true} progressText='Loading...' />
            <ConfigureRpiWifi />
            <Button onPress={() => {
                navigation.navigate('Home', {})
            }} style={buttonStyle.primaryButton} mode='contained'>CONTINUE</Button>
        </Box>
    );
}