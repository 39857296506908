import { ActionType, getType } from "typesafe-actions";
import { IGrainProfile, IGrainProfileMapping } from "../../models";
import * as actions from "../actions/grainProfileAction";

export interface IGrainProfileState {
    profileId: string;
    readonly grainProfiles: IGrainProfile[] | undefined;
    readonly grainProfileMappings: IGrainProfileMapping[] | undefined;
    selectedGrainProfileMapping: IGrainProfileMapping | undefined;
}

export const initialGrainProfileState: IGrainProfileState = {
    profileId: "1",
    grainProfiles: undefined,
    grainProfileMappings: undefined,
    selectedGrainProfileMapping: undefined
};

export const GrainProfileReducer = (
    state: IGrainProfileState = initialGrainProfileState,
    action: ActionType<typeof actions>
): IGrainProfileState => {
    switch (action.type) {
        case getType(actions.setProfileId):
            return {
                ...state,
                profileId: action.payload
            };
        case getType(actions.setGrainProfiles):
            return {
                ...state,
                grainProfiles: action.payload
            };
        case getType(actions.setGrainProfileMappings):
            return {
                ...state,
                grainProfileMappings: action.payload
            };
        case getType(actions.setSelectedGrainProfileMapping):
            return {
                ...state,
                selectedGrainProfileMapping: action.payload
            };
        case getType(actions.reset):
            return initialGrainProfileState;
        default:
            return state;
    }
};