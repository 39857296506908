import { IDateRange, IJobListTimestampRange, ISearchQuery } from "src/models";
import { createAction, createAsyncAction } from "typesafe-actions";

export const setGrainProfileModalVisible = createAction('SET_GRAIN_PROFILE_MODAL_VISIBLE')<boolean>();

export const setSearchQuery = createAction('SET_SEARCH_QUERY')<ISearchQuery | undefined>();

export const setDateRange = createAction('SET_DATE_RANGE')<IDateRange | undefined>();

export const setGrainIdFilter = createAction('SET_GRAIN_ID_FILTER')<string | undefined>();

export const setCenterIdFilter = createAction('SET_CENTER_ID_FILTER')<string | undefined>();

export const setStateFilter  = createAction('SET_STATE_FILTER')<string | undefined>();

export const setDistrictFilter = createAction('SET_DISTRICT_FILTER')<string | undefined>();

export const setCurrentStack = createAction('SET_CURRENT_STACK')<string>();

export const setUserJobsCachedTimestampRange = createAction('SET_USER_JOBS_CACHED_TIMESTAMP_RANGE')<IJobListTimestampRange>();

export const setOrgJobsCachedTimestampRange = createAction('SET_ORG_JOBS_CACHED_TIMESTAMP_RANGE')<IJobListTimestampRange>();

export const resetFilters = createAction('RESET_FILTERS')<void>();

export const setAppColorMode = createAction('SET_APP_COLOR_MODE')<string>();

export const reset = createAction('RESET')<void>();

export const logout = createAction('LOGOUT')<void>();

export const forceLogout = createAction('FORCE_LOGOUT')<void>();

export const forceLogoutHandled = createAction('FORCE_LOGOUT_HANDLED')<void>();

