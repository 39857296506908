import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions/uploadAction";
import { IUploadJobImageRequest, IUploadJobInfoImageFailure, IUploadJobInfoImageRequest, IUploadJobInfoImageSuccess } from "src/models";

export interface IUploadState {
    readonly upload: IUploadFileState;
    readonly uploadLogo: IUploadFileState;
    readonly uploadJobInfoImage: { [key: string]: IUploadFileState };
}

export interface IUploadFileState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly success: boolean;
    readonly s3Path?: string;
}

export const initialUploadState: IUploadState = {
    upload: {
        error: undefined,
        isFetching: false,
        success: false,
        s3Path: undefined
    },
    uploadLogo: {
        error: undefined,
        isFetching: false,
        success: false,
        s3Path: undefined
    },
    uploadJobInfoImage: {

    }
};

export const UploadReducer = (
    state: IUploadState = initialUploadState,
    action: ActionType<typeof actions>
): IUploadState => {
    switch (action.type) {
        case getType(actions.upload.request):
            return {
                ...state,
                upload: {
                    error: undefined,
                    isFetching: true,
                    success: false,
                    s3Path: undefined
                }
            };
        case getType(actions.upload.success):
            return {
                ...state,
                upload: {
                    error: undefined,
                    isFetching: false,
                    success: true,
                    s3Path: action.payload || undefined
                }
            };
        case getType(actions.upload.failure):
            return {
                ...state,
                upload: {
                    error: action.payload,
                    isFetching: false,
                    success: false,
                    s3Path: undefined
                }
            };
        case getType(actions.uploadLogo.request):
            return {
                ...state,
                uploadLogo: {
                    error: undefined,
                    isFetching: true,
                    success: false,
                    s3Path: undefined
                }
            };
        case getType(actions.uploadLogo.success):
            return {
                ...state,
                uploadLogo: {
                    error: undefined,
                    isFetching: false,
                    success: true,
                    s3Path: action.payload || undefined
                }
            };
        case getType(actions.uploadLogo.failure):
            return {
                ...state,
                uploadLogo: {
                    error: action.payload,
                    isFetching: false,
                    success: false,
                    s3Path: undefined
                }
            };
        case getType(actions.uploadJobInfoImage.request):
            const payload = action.payload as IUploadJobInfoImageRequest;

            const keyRequest = `${payload.jobId}_${payload.fieldName}`

            return {
                ...state,
                uploadJobInfoImage: {
                    ...state.uploadJobInfoImage,
                    [keyRequest]: {
                        error: undefined,
                        isFetching: true,
                        success: false,
                        s3Path: undefined
                    }
                }
            };
        case getType(actions.uploadJobInfoImage.success):

            const payloadSuccess = action.payload as IUploadJobInfoImageSuccess;

            const key = `${payloadSuccess.jobId}_${payloadSuccess.fieldName}`

            return {
                ...state,
                uploadJobInfoImage: {
                    ...state.uploadJobInfoImage,
                    [key]: {
                        error: undefined,
                        isFetching: false,
                        success: true,
                        s3Path: payloadSuccess.s3Path
                    }
                }
            };
        case getType(actions.uploadJobInfoImage.failure):

            const payloadFailure = action.payload as IUploadJobInfoImageFailure;

            const keyFailure = `${payloadFailure.jobId}_${payloadFailure.fieldName}`

            return {
                ...state,
                uploadJobInfoImage: {
                    ...state.uploadJobInfoImage,
                    [keyFailure]: {
                        error: payloadFailure.error,
                        isFetching: false,
                        success: false,
                        s3Path: undefined
                    }
                }
            };
        case getType(actions.reset):
            return initialUploadState;
        default:
            return state;
    }
};