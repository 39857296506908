import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions/loginAction";

export interface ILoginActionState {
    userLoggedIn: string,
    globalLogoutState: IGlobalLogoutState;
}

export interface IGlobalLogoutState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response: string | undefined;
}

export const initialLoginState: ILoginActionState = {
    userLoggedIn: "UNKNOWN",
    globalLogoutState: {
        error: undefined,
        isFetching: false,
        response: undefined
    },
};

export const LoginReducer = (
    state: ILoginActionState = initialLoginState,
    action: ActionType<typeof actions>
): ILoginActionState => {
    switch (action.type) {
        case getType(actions.globalLogout.request):
            return {
                ...state,
                globalLogoutState: {
                    ...state.globalLogoutState,
                    error: undefined,
                    isFetching: true,
                    response: "UNKNOWN"
                }
            };
        case getType(actions.globalLogout.success):
            return {
                ...state,
                globalLogoutState: {
                    error: undefined,
                    isFetching: false,
                    response: action.payload
                }
            };
        case getType(actions.globalLogout.failure):
            return {
                ...state,
                globalLogoutState: {
                    ...state.globalLogoutState,
                    response: "FAILED",
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.setUserLoggedIn):
            return {
                ...state,
                userLoggedIn: action.payload
            };
        case getType(actions.reset):
            return initialLoginState;
        default:
            return state;
    }
};