import * as Effects from "redux-saga/effects";

import * as UserAction from "../actions/userAction";
import * as GrainTypeAction from "../actions/grainTypeAction";
import * as api from "../../api";
import { showErrorMessage } from "./shared";
import { IStore } from "../reducers";
import { select } from "redux-saga/effects";
import { IGrainWithProfiles, IUserProfile } from "src/models";
import { getCacheExpiryDuration } from "src/utils/remoteConfig";

const call: any = Effects.call;
const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;

export const getUserGroups = (store: IStore) => store.user.userProfile.response?.groups;
export const getExpiry = (store: IStore) => store.user.userProfile.expiry;
export const getCachedUserProfile = (state: IStore) => state.user.userProfile.response;
export const getGrainId = (state: any) => state.grainType.grainId;

export function* logUserActivity(action: ReturnType<typeof UserAction.logUserActivity.request>): any {
  try {
    const response = yield call(api.logUserActivity, action.payload);

    yield put(UserAction.logUserActivity.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UserAction.logUserActivity.failure(error));
  }
}

export function* getUserSettings(action: ReturnType<typeof UserAction.getUserSettings.request>): any {
  try {
    const response = yield call(api.getUserSettings, action.payload);

    yield put(UserAction.getUserSettings.success({
      key: action.payload.settingName,
      value: response['settings']
    }));
  } catch (error: any) {
    showErrorMessage(error);
  }
}

export function* getUserProfile(action: ReturnType<typeof UserAction.getUserProfile.request>): any {
  try {
    let expiry = yield select(getExpiry);
    let expiryDate = expiry === undefined ? undefined : new Date(expiry);

    let currentDate = new Date();
    let cachedUserProfile: IUserProfile = yield select(getCachedUserProfile);

    if (cachedUserProfile !== undefined
      && expiryDate !== undefined
      && expiryDate.getTime() > currentDate.getTime()) {
      console.log("Using cached user profile");
      yield put(UserAction.getUserProfile.success({
        cached: true,
        profile: cachedUserProfile,
        expiry: expiryDate
      }))
    } else {
      console.log("Fetching new user profile");
      let newProfile = yield call(api.getUserProfile, action.payload);

      let expiry = new Date();
      expiry.setSeconds(expiry.getSeconds() + getCacheExpiryDuration());

      yield put(UserAction.getUserProfile.success({
        cached: false,
        profile: newProfile,
        expiry: expiry
      }));
      cachedUserProfile = newProfile;
    }

    yield call(updateGrainId, cachedUserProfile);
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UserAction.getUserProfile.failure(error));
  }
}

function* updateGrainId(cachedUserProfile: IUserProfile): Generator<any, void, any> {
  let initialGrainId = yield select(getGrainId);
  let grainId = initialGrainId;

  let isGrainIdAllowed = cachedUserProfile
    .grains
    .some((grain: IGrainWithProfiles) => grain.grainId === grainId);

  if (!isGrainIdAllowed) {
    grainId = cachedUserProfile.grains[0].grainId;
  }

  if (initialGrainId !== grainId) {
    yield put(GrainTypeAction.setGrainId(grainId));
  }
}

export function* updateUserSettings(action: ReturnType<typeof UserAction.updateUserSettings.request>): any {
  try {
    const response = yield call(api.updateUserSettings, action.payload);

    yield put(UserAction.updateUserSettings.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UserAction.updateUserSettings.failure(error));
  }
}

export function* updateUserLogo(action: ReturnType<typeof UserAction.updateUserLogo.request>): any {
  try {
    const response = yield call(api.updateUserLogo, action.payload);

    yield put(UserAction.updateUserLogo.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UserAction.updateUserLogo.failure(error));
  }
}

/*
 * WATCHERS
 */

export function* watchLogUserActivity() {
  yield takeEvery(UserAction.logUserActivity.request, logUserActivity);
}

export function* watchGetUserSettings() {
  yield takeEvery(UserAction.getUserSettings.request, getUserSettings);
}

export function* watchGetUserProfile() {
  yield takeEvery(UserAction.getUserProfile.request, getUserProfile);
}

export function* watchUpdateUserSettings() {
  yield takeEvery(UserAction.updateUserSettings.request, updateUserSettings);
}

export function* watchUpdateUserLogo() {
  yield takeEvery(UserAction.updateUserLogo.request, updateUserLogo);
}

export default function* root() {
  yield all([
    fork(watchLogUserActivity),
    fork(watchGetUserProfile),
    fork(watchGetUserSettings),
    fork(watchUpdateUserLogo),
    fork(watchUpdateUserSettings)]);
}