import * as React from 'react';
import { Box, Button } from 'native-base';
import * as AppActions from '../../redux/actions/appAction';
import * as UserActions from '../../redux/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { buttonStyle } from 'src/styles/buttonStyle';
import { containerStyle } from 'src/styles/containerStyle';
import ProgressBar from 'src/components/ProgressBar';
import { IStore } from 'src/redux/reducers';
import { stackHomeScreen, defaultStackForGroup } from 'src/stack/screens';

export default function ChooseLoginTypePage({ navigation }) {

    const currentStack = useSelector((store: IStore) => store.app.currentStack);
    
    const isFetchingUserProfile = useSelector((store: IStore) => store.user.userProfile.isFetching);

    const userProfile = useSelector((store: IStore) => store.user.userProfile.response);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if(userProfile === undefined) {
            dispatch(UserActions.getUserProfile.request())
            return
        }

        initStackOptions()

    }, [userProfile]);

    const initStackOptions = async () => {
        if (userProfile?.groups.length == 1) {
            await setStack(userProfile?.groups[0])
            return
        }

        if (currentStack !== 'stackChooser') {
            const nextScreen = stackHomeScreen(currentStack)
            navigation.navigate(nextScreen)
            return
        }
    }

    const setStack = async (groupName: string) => {
        const stack = defaultStackForGroup(groupName)
        if (stack === currentStack) {
            const nextScreen = stackHomeScreen(stack)
            navigation.navigate(nextScreen)
            return
        }
        dispatch(AppActions.setCurrentStack(stack))

        if (currentStack === 'stackChooser') {
            const nextScreen = stackHomeScreen(stack)
            navigation.navigate(nextScreen)
        }
    }

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isVisibile={isFetchingUserProfile} progressText='Loading your experience...' />
            {
                !isFetchingUserProfile &&
                userProfile?.groups.map((groupName, index) => {
                    return (
                        <Button key={index} style={buttonStyle.primaryButton} onPress={() => setStack(groupName)}>
                            {groupName}
                        </Button>
                    )
                })
            }
        </Box>
    );
}