import { IOrg } from "src/models";
import { ActionType, getType } from "typesafe-actions";
import { ICenter, IDistrict } from "../../models/Center";
import * as actions from "../actions/orgAction";
import { ISurveyor } from 'src/models/Surveyor';
import { IOrgStatistics } from "src/models/UserStatistics";

export interface IOrgState {
    expiry: Date | undefined;
    centerId: string | undefined;
    centers: ICentersState;
    centersWithStats: ICentersState;
    centerWithTrucks: ICenterState;
    centerDistricts: IDistrictState;
    isFetchingActiveUsers: boolean;
    activeUsersError?: Error;
    surveyors: ISurveyor[];
    isFetchingSurveyors: boolean;
    surveyorsError?: Error;
    orgStatistics: IOrgStatistics | undefined;
}

export interface ICentersState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly centers: ICenter[] | [];
}

export interface ICenterState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly center: ICenter | undefined;
}

export interface IDistrictState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly districts: IDistrict[] | [];
}

export const initialOrgState: IOrgState = {
    centerId: "all",
    expiry: undefined,
    centers: {
        error: undefined,
        isFetching: false,
        centers: []
    },
    centersWithStats: {
        error: undefined,
        isFetching: false,
        centers: []
    },
    orgStatistics: undefined,
    isFetchingActiveUsers: false,
    activeUsersError: undefined,
    surveyors: [],
    isFetchingSurveyors: false,
    surveyorsError: undefined,
    centerWithTrucks: {
        error: undefined,
        isFetching: false,
        center: undefined
    },
    centerDistricts: {
        error: undefined,
        isFetching: false,
        districts: []
    }
};

export const OrgReducer = (
    state: IOrgState = initialOrgState,
    action: ActionType<typeof actions>
): IOrgState => {
    switch (action.type) {
        case getType(actions.setCenterId):
            return {
                ...state,
                centerId: action.payload
            };
        case getType(actions.getCenters.request):
            return {
                ...state,
                centers: {
                    ...state.centers,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.getCenters.success):
            return {
                ...state,
                expiry: action.payload.expiry,
                centers: {
                    error: undefined,
                    isFetching: false,
                    centers: action.payload.centers
                }
            };
        case getType(actions.getCenters.failure):
            return {
                ...state,
                centers: {
                    ...state.centers,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.getCentersWithStats.request):
            return {
                ...state,
                centersWithStats: {
                    ...state.centersWithStats,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.getCentersWithStats.success):
            return {
                ...state,
                centersWithStats: {
                    error: undefined,
                    isFetching: false,
                    centers: action.payload
                }
            };
        case getType(actions.getCentersWithStats.failure):
            return {
                ...state,
                centersWithStats: {
                    ...state.centersWithStats,
                    error: action.payload,
                    isFetching: false
                }
            };
        case getType(actions.fetchOrgStatistics.request):
            return {
                ...state,
                isFetchingActiveUsers: true,
                activeUsersError: undefined
            };
        case getType(actions.fetchOrgStatistics.success):
            return {
                ...state,
                isFetchingActiveUsers: false,
                orgStatistics: action.payload
            };
        case getType(actions.fetchOrgStatistics.failure):
            return {
                ...state,
                isFetchingActiveUsers: false,
                activeUsersError: action.payload
            };
        case getType(actions.fetchSurveyors.request):
            return {
                ...state,
                isFetchingSurveyors: true,
                surveyorsError: undefined
            };
        case getType(actions.fetchSurveyors.success):
            return {
                ...state,
                isFetchingSurveyors: false,
                surveyors: action.payload
            };
        case getType(actions.fetchSurveyors.failure):
            return {
                ...state,
                isFetchingSurveyors: false,
                surveyorsError: action.payload
            }
        case getType(actions.getCenterTrucks.request):
            return {
                ...state,
                centerWithTrucks: {
                    ...state.centerWithTrucks,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.getCenterTrucks.success):
            return {
                ...state,
                centerWithTrucks: {
                    error: undefined,
                    isFetching: false,
                    center: action.payload
                }
            };
        case getType(actions.getCenterTrucks.failure):
            return {
                ...state,
                centerWithTrucks: {
                    ...state.centerWithTrucks,
                    error: action.payload,
                    isFetching: false
                }
            };
        case getType(actions.getCenterDistricts.request):
            return {
                ...state,
                centerDistricts: {
                    ...state.centerDistricts,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.getCenterDistricts.success):
            return {
                ...state,
                centerDistricts: {
                    error: undefined,
                    isFetching: false,
                    districts: action.payload
                }
            };
        case getType(actions.getCenterDistricts.failure):
            return {
                ...state,
                centerDistricts: {
                    ...state.centerDistricts,
                    error: action.payload,
                    isFetching: false
                }
            };
        case getType(actions.reset):
            return initialOrgState;
        default:
            return state;
    }
};