import * as React from 'react';
import OrgSuperAdminStatusBar from 'src/components/OrgSuperAdminStatusBar';
import { containerStyle } from 'src/styles/containerStyle';
import { Box } from 'native-base';
import OrgDashboardTabs from 'src/components/OrgDashboardTabs';
import OrgSuperAdminJobFiltersComponent from 'src/components/OrgSuperAdminJobFiltersComponent';

export default function OrganizationDashboardPage({ navigation }) {

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <Box style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <OrgSuperAdminJobFiltersComponent />
                <Box style={{
                    flex: 1,
                    width: '100%',
                    flexDirection: 'column',
                }}>
                    <Box style={{
                        marginTop: 12,
                        marginBottom: 16,
                    }}>
                        <OrgSuperAdminStatusBar showCollapsibleLayout={false}/>
                    </Box>
                    <OrgDashboardTabs navigation={navigation} />
                </Box>
            </Box>
        </Box>
    );
}