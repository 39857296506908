import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Auth as Authorization } from 'aws-amplify';
import { Button, FormControl, Input, VStack, Text, Box } from 'native-base';
import { containerStyle } from 'src/styles/containerStyle';
import { inputStyle } from 'src/styles/inputStyle';
import ProgressBar from 'src/components/ProgressBar';
import InweonLogo from 'src/components/InweonLogo';
import { formProps } from 'src/styles/formProps';

interface ForgotPasswordFormState {
    username: string,
}

export default function ForgotPasswordPage({ navigation }) {

    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [formData, setData] = React.useState<ForgotPasswordFormState>({
        username: ''
    });

    const [errors, setErrors] = React.useState({});

    const validate = () => {
        if (formData.username === undefined
            || formData.username.length === 0) {
            setErrors({
                username: 'Username is required'
            });
            return false;
        }
        return true;
    };

    const handleForgotPassword = async () => {
        setShowLoadingIndicator(true)
        Authorization.forgotPassword(formData.username).then(async () => {
            setShowLoadingIndicator(true)
            navigation.navigate('ConfirmForgotPasswordPage', {
                "username": formData.username
            })
        }).catch((e) => {
            console.log('error occurred', e)

            setErrors({
                formError: e.message
            });

            setShowLoadingIndicator(false)
        });
    }

    const onSubmit = () => {
        const isValid = validate();
        if (isValid) {
            // submit the data
            handleForgotPassword()
        }
    };

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isVisibile={showLoadingIndicator} progressText="Loading..." />
            <ScrollView automaticallyAdjustKeyboardInsets={true}>
                <InweonLogo title={"Reset your password"} />

                <VStack width={formProps.width}
                    style={containerStyle.formContainer} mx="3">
                    <FormControl isRequired>
                        <FormControl.Label>Username</FormControl.Label>
                        <Input
                            style={inputStyle.nativeInput}
                            isInvalid={'username' in errors}
                            placeholder="username"
                            onSubmitEditing={onSubmit}
                            onChangeText={value => setData({
                                ...formData,
                                username: value
                            })} />
                        {'username' in errors ? <FormControl.ErrorMessage>{errors['username']}</FormControl.ErrorMessage> : null}
                    </FormControl>
                    <Button onPress={onSubmit} mt="5">
                        Send confirmation code
                    </Button>
                    {'formError' in errors ? <Text color="red.500" mt="2">{errors['formError'] || ""}</Text> : null}
                </VStack>
            </ScrollView>

        </Box>
    );
}