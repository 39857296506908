import * as React from 'react';
import { useSelector } from 'react-redux';
import OrgJobHistoryList from 'src/components/list/OrgJobHistoryList';
import OrgStatusBar from 'src/components/OrgStatusBar';
import { IStore } from 'src/redux/reducers';
import ProgressBar from 'src/components/ProgressBar';
import { containerStyle } from 'src/styles/containerStyle';
import { Box } from 'native-base';
import { store } from 'src/redux/store';

export default function OrganizationJobsPage({ navigation }) {
    console.log(store.getState())
    const isFetching = useSelector((store: IStore) => store.job.orgJobs.isFetching);
    const organizationId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
    // const organizationId = useSelector((store: IStore) => store.user.userProfile.org.orgId);
    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isVisibile={isFetching} progressText="Loading..." />
            <OrgStatusBar organizationId={organizationId}/>
            <OrgJobHistoryList navigation={navigation} />
        </Box>
    );
}