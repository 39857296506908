import * as Effects from "redux-saga/effects";

import * as LoginAction from "../actions/loginAction";
import * as UserAction from "../actions/userAction";
import * as api from "../../api";
import { call } from "redux-saga/effects";
import { showErrorMessage } from "./shared";

const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;

export function* performGlobalLogout(action: ReturnType<typeof LoginAction.globalLogout.request>): any {
  try {
    const response = yield call(api.globalLogout, action.payload);
    yield put(LoginAction.globalLogout.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(LoginAction.globalLogout.failure(error));
  }
}

export function* onLoginCompleted(action: ReturnType<typeof LoginAction.setUserLoggedIn>): any {
  try {
    if (action.payload !== "LOGGED_IN") {
      return;
    }

    // perform login success callbacks here
    yield put(UserAction.getUserProfile.request());
  } catch (error: any) {
    showErrorMessage(error);
  }
}

export function* watchGlobalLogout() {
  yield takeEvery(LoginAction.globalLogout.request, performGlobalLogout);
}

export function* watchLoginCompleted() {
  yield takeEvery(LoginAction.setUserLoggedIn, onLoginCompleted);
}

export default function* root() {
  yield all(
    [
      fork(watchGlobalLogout),
      fork(watchLoginCompleted)
    ]);
}