import * as Effects from 'redux-saga/effects';
import * as UserGuideActions from '../actions/userGuideActions';
import * as api from '../../api/userGuide';
import { showErrorMessage } from './shared';

const call: any = Effects.call;
const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;

export function* getFaqItems(action: ReturnType<typeof UserGuideActions.getFaqItems.request>): any {
  try {
    const response = yield call(api.getFaqItems, action.payload);
    yield put(UserGuideActions.getFaqItems.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UserGuideActions.getFaqItems.failure(error));
  }
}

/*
 * WATCHERS
 */
export function* watchGetFaqItems() {
  yield takeEvery(UserGuideActions.getFaqItems.request, getFaqItems);
}

export default function* root() {
  yield all([fork(watchGetFaqItems)]);
}
