import { IAnalyseScanRequest, IAnalyseRetrialRequest, IAnalyseUploadRequest, IAnalyseDoubleSidedUploadRequest, IAnalyseManualJobRequest } from "../../models";
import { createAsyncAction } from "typesafe-actions";

export const initiateScan = createAsyncAction(
    "scan/INITIATE_SCAN_REQUEST",
    "scan/INITIATE_SCAN_SUCCESS",
    "scan/INITIATE_SCAN_FAILURE"
)<IAnalyseScanRequest, Boolean, void>();

export const initiateUpload = createAsyncAction(
    "scan/INITIATE_UPLOAD_REQUEST",
    "scan/INITIATE_UPLOAD_SUCCESS",
    "scan/INITIATE_UPLOAD_FAILURE"
)<IAnalyseUploadRequest, Boolean, void>();

export const initiateDoubleSidedUpload = createAsyncAction(
    "scan/INITIATE_DOUBLE_SIDED_UPLOAD_REQUEST",
    "scan/INITIATE_DOUBLE_SIDED_UPLOAD_SUCCESS",
    "scan/INITIATE_DOUBLE_SIDED_UPLOAD_FAILURE"
)<IAnalyseDoubleSidedUploadRequest, Boolean, void>();

export const initiateManualJob = createAsyncAction(
    "scan/INITIATE_MANUAL_JOB_REQUEST",
    "scan/INITIATE_MANUAL_JOB_SUCCESS",
    "scan/INITIATE_MANUAL_JOB_FAILURE"
)<IAnalyseManualJobRequest, Boolean, void>();

export const initiateTestJob = createAsyncAction(
    "scan/INITIATE_TEST_JOB_REQUEST",
    "scan/INITIATE_TEST_JOB_SUCCESS",
    "scan/INITIATE_TEST_JOB_FAILURE"
)<void, Boolean, void>();

export const initiateRetrial = createAsyncAction(
    "scan/INITIATE_RETRIAL_REQUEST",
    "scan/INITIATE_RETRIAL_SUCCESS",
    "scan/INITIATE_RETRIAL_FAILURE"
)<IAnalyseRetrialRequest, Boolean, void>();