import { getUniqueJobs } from "../../utils/utils";
import { ActionType, getType } from "typesafe-actions";
import { IJob, IJobListTimestampRange, IJobRequest, IJobs, JobStatus } from "../../models";
import * as actions from "../actions/jobAction";

export interface IJobState {
    jobId?: string,
    currentJobStatus: JobStatus,
    waitingForScan: boolean,
    jobRequest?: IJobRequest,
    createJob: ICreateJobState,
    queueJob: ICreateJobState,
    updateJob: IUpdateJobState,
    updateJobResult: IUpdateJobResultState,
    jobStatus: IJobStatusState,
    userJobs: IUserJobsState,
    userJobsRange: IJobListTimestampRange | undefined,
    orgJobs: IUserJobsState,
    orgJobsRange: IJobListTimestampRange | undefined,
}

export interface ICreateJobState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly success: Boolean | null;
}

export interface IQueueJobState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly success: Boolean | null;
}

export interface IUpdateJobState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly success: Boolean | null;
}

export interface IUpdateJobResultState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly success: Boolean | null;
}
export interface IJobStatusState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly job?: IJob | null;
}

export interface IUserJobsState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly jobs?: IJobs | null;
    readonly lastFetch: Date;
}

export const initialJobState: IJobState = {
    jobId: undefined,
    currentJobStatus: JobStatus.IDLE,
    waitingForScan: false,
    jobRequest: undefined,
    createJob: {
        error: undefined,
        isFetching: false,
        success: false
    },
    queueJob: {
        error: undefined,
        isFetching: false,
        success: false
    },
    updateJob: {
        error: undefined,
        isFetching: false,
        success: false
    },
    jobStatus: {
        error: undefined,
        isFetching: false,
        job: undefined
    },
    userJobs: {
        error: undefined,
        isFetching: false,
        jobs: undefined,
        lastFetch: new Date()
    },
    userJobsRange: undefined,
    orgJobs: {
        error: undefined,
        isFetching: false,
        jobs: undefined,
        lastFetch: new Date()
    },
    orgJobsRange: undefined,
    updateJobResult: {
        error: undefined,
        isFetching: false,
        success: false
    }
};

export const JobReducer = (
    state: IJobState = initialJobState,
    action: ActionType<typeof actions>
): IJobState => {
    switch (action.type) {
        case getType(actions.setJobId):
            return {
                ...state,
                jobId: action.payload
            };
        case getType(actions.setJobRequest):
            return {
                ...state,
                jobRequest: action.payload
            };
        case getType(actions.updateRpiJobStatus):
            const currentJobId = state.jobId;
            if (currentJobId === action.payload.jobId) {
                return {
                    ...state,
                    currentJobStatus: action.payload.status
                };
            } else {
                return state
            };
        case getType(actions.setWaitingForScan):
            return {
                ...state,
                waitingForScan: action.payload
            }
        case getType(actions.jobStatus.request):
            return {
                ...state,
                currentJobStatus: JobStatus.IDLE,
                jobStatus: {
                    error: undefined,
                    isFetching: true,
                    job: undefined
                }
            };
        case getType(actions.jobStatus.success):
            return {
                ...state,
                currentJobStatus: action.payload?.jobStatus as JobStatus || JobStatus.IDLE,
                jobStatus: {
                    error: undefined,
                    isFetching: false,
                    job: action.payload
                }
            };
        case getType(actions.jobStatus.failure):
            return {
                ...state,
                currentJobStatus: JobStatus.IDLE,
                jobStatus: {
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.createJob.request):
            return {
                ...state,
                createJob: {
                    error: undefined,
                    isFetching: true,
                    success: false
                }
            };
        case getType(actions.createJob.success):
            return {
                ...state,
                createJob: {
                    error: undefined,
                    isFetching: false,
                    success: action.payload
                }
            };
        case getType(actions.createJob.failure):
            return {
                ...state,
                createJob: {
                    ...state.createJob,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.queueJob.request):
            return {
                ...state,
                queueJob: {
                    error: undefined,
                    isFetching: true,
                    success: false
                }
            };
        case getType(actions.queueJob.success):
            return {
                ...state,
                queueJob: {
                    error: undefined,
                    isFetching: false,
                    success: action.payload
                }
            };
        case getType(actions.queueJob.failure):
            return {
                ...state,
                queueJob: {
                    ...state.createJob,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.updateJob.request):
            return {
                ...state,
                updateJob: {
                    error: undefined,
                    isFetching: true,
                    success: false
                }
            };
        case getType(actions.updateJob.success):
            return {
                ...state,
                updateJob: {
                    error: undefined,
                    isFetching: false,
                    success: action.payload
                }
            };
        case getType(actions.updateJob.failure):
            return {
                ...state,
                updateJob: {
                    ...state.updateJob,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.updateJobResult.request):
            return {
                ...state,
                updateJobResult: {
                    error: undefined,
                    isFetching: true,
                    success: false
                }
            };
        case getType(actions.updateJobResult.success):
            return {
                ...state,
                updateJobResult: {
                    error: undefined,
                    isFetching: false,
                    success: action.payload
                }
            };
        case getType(actions.updateJobResult.failure):
            return {
                ...state,
                updateJobResult: {
                    ...state.updateJobResult,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.pollJobStatus.request):
            return {
                ...state,
                currentJobStatus: JobStatus.IDLE,
                jobStatus: {
                    error: undefined,
                    isFetching: true,
                    job: undefined
                }
            };
        case getType(actions.pollJobStatus.success):
            return {
                ...state,
                currentJobStatus: action.payload?.jobStatus as JobStatus || JobStatus.IDLE,
                jobStatus: {
                    error: undefined,
                    isFetching: false,
                    job: action.payload
                }
            };
        case getType(actions.pollJobStatus.failure):
            return {
                ...state,
                currentJobStatus: JobStatus.IDLE,
                jobStatus: {
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.userJobs.request):
            return {
                ...state,
                userJobs: {
                    ...state.userJobs,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.userJobs.success):
            var jobs = state.userJobs.jobs?.Items || []
            if (action.payload?.Items !== undefined) {
                jobs.push(...action.payload?.Items)
            }

            var uniqueJobs = getUniqueJobs(jobs, (it: IJob) => it.jobId)

            return {
                ...state,
                userJobs: {
                    error: undefined,
                    isFetching: false,
                    jobs: {
                        "Items": uniqueJobs
                    },
                    lastFetch: new Date()
                }
            };
        case getType(actions.userJobs.failure):
            return {
                ...state,
                userJobs: {
                    ...state.userJobs,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.pollUserJobs.request):
            return {
                ...state,
                userJobs: {
                    ...state.userJobs,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.pollUserJobs.success):
            var jobs = state.userJobs.jobs?.Items || []
            if (action.payload?.Items !== undefined) {
                jobs.push(...action.payload?.Items)
            }

            var uniqueJobs = getUniqueJobs(jobs, (it: IJob) => it.jobId)
            return {
                ...state,
                userJobs: {
                    error: undefined,
                    isFetching: false,
                    jobs: {
                        "Items": uniqueJobs
                    },
                    lastFetch: new Date()
                }
            };
        case getType(actions.pollUserJobs.failure):
            return {
                ...state,
                userJobs: {
                    ...state.userJobs,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.orgJobs.request):
            return {
                ...state,
                orgJobs: {
                    ...state.orgJobs,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.orgJobs.success):
            var jobs = state.orgJobs.jobs?.Items || []
            if (action.payload?.Items !== undefined) {
                jobs.push(...action.payload?.Items)
            }

            var uniqueJobs = getUniqueJobs(jobs, (it: IJob) => it.jobId)

            return {
                ...state,
                orgJobs: {
                    error: undefined,
                    isFetching: false,
                    jobs: {
                        "Items": uniqueJobs
                    },
                    lastFetch: new Date()
                }
            };
        case getType(actions.orgJobs.failure):
            return {
                ...state,
                orgJobs: {
                    ...state.orgJobs,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.pollOrgJobs.request):
            return {
                ...state,
                orgJobs: {
                    ...state.orgJobs,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.pollOrgJobs.success):
            var jobs = state.orgJobs.jobs?.Items || []
            if (action.payload?.Items !== undefined) {
                jobs.push(...action.payload?.Items)
            }

            var uniqueJobs = getUniqueJobs(jobs, (it: IJob) => it.jobId)
            return {
                ...state,
                orgJobs: {
                    error: undefined,
                    isFetching: false,
                    jobs: {
                        "Items": uniqueJobs
                    },
                    lastFetch: new Date()
                }
            };
        case getType(actions.pollOrgJobs.failure):
            return {
                ...state,
                orgJobs: {
                    ...state.orgJobs,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.setUserJobsTimestampRange):
            return {
                ...state,
                userJobsRange: action.payload
            };
        case getType(actions.setOrgJobsTimestampRange):
            return {
                ...state,
                orgJobsRange: action.payload
            };
        case getType(actions.reset):
            return initialJobState;
        default:
            return state;
    }
};