import { createAction, createAsyncAction } from "typesafe-actions";

export const reset = createAction('RESET')<void>();

export const globalLogout = createAsyncAction(
    "scan/GLOBAL_LOGOUT_BEFORE_LOGIN_REQUEST",
    "scan/GLOBAL_LOGOUT_BEFORE_LOGIN_SUCCESS",
    "scan/GLOBAL_LOGOUT_BEFORE_LOGIN_FAILURE"
)<string, string, Error>();

export const setUserLoggedIn = createAction('SET_USER_LOGGED_IN')<string>();