import { createAction, createAsyncAction } from "typesafe-actions";
import { IUploadDoubleSidedJobImageRequest, IUploadJobImageRequest, IUploadJobInfoImageFailure, IUploadJobInfoImageRequest, IUploadJobInfoImageSuccess, IUploadLogoImageRequest } from "../../models";

export const reset = createAction('RESET')<void>();

export const upload = createAsyncAction(
    "upload/PRESIGNED_URL_REQUEST",
    "upload/PRESIGNED_URL_SUCCESS",
    "upload/PRESIGNED_URL_FAILURE"
)<IUploadJobImageRequest, string, Error>();

export const uploadDoubleSided = createAsyncAction(
    "upload/DOUBLE_SIDED_PRESIGNED_URL_REQUEST",
    "upload/DOUBLE_SIDED_PRESIGNED_URL_SUCCESS",
    "upload/DOUBLE_SIDED_PRESIGNED_URL_FAILURE"
)<IUploadDoubleSidedJobImageRequest, string, Error>();

export const uploadLogo = createAsyncAction(
    "upload/PRESIGNED_LOGO_URL_REQUEST",
    "upload/PRESIGNED_LOGO_URL_SUCCESS",
    "upload/PRESIGNED_LOGO_URL_FAILURE"
)<IUploadLogoImageRequest, string, Error>();

export const uploadJobInfoImage = createAsyncAction(
    "upload/PRESIGNED_JOB_INFO_IMAGE_URL_REQUEST",
    "upload/PRESIGNED_JOB_INFO_IMAGE_URL_SUCCESS",
    "upload/PRESIGNED_JOB_INFO_IMAGE_URL_FAILURE"
)<IUploadJobInfoImageRequest, IUploadJobInfoImageSuccess, IUploadJobInfoImageFailure>();