import * as Effects from "redux-saga/effects";

import * as ReportAction from "../actions/reportAction";
import * as api from "../../api/report";
import { showErrorMessage } from "./shared";

const call: any = Effects.call;
const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;

export function* getReport(action: ReturnType<typeof ReportAction.getJobReport.request>): any {
  try {
    const response = yield call(api.getReport, action.payload);
    yield put(ReportAction.getJobReport.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(ReportAction.getJobReport.failure(error));
  }
}
/*
 * WATCHERS
 */

export function* watchGetReport() {
  yield takeEvery(ReportAction.getJobReport.request, getReport);
}

export default function* root() {
  yield all([fork(watchGetReport)]);
}