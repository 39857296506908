import * as React from 'react';
import { View, ScrollView } from 'react-native';
import GrainCumulativeAnalysisTable from '../../components/GrainCumulativeAnalysisTable';
import ParticleDistributionTable from '../../components/ParticleDistributionTable';
import { IStore } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { JobStatus } from '../../models';
import { Box, Text } from "native-base";
import { useEffect } from 'react';
import GrainTopLevelAnalysisTable from 'src/components/GrainTopLevelAnalysisTable';
import { containerStyle } from 'src/styles/containerStyle';
import DownloadResultButtons from 'src/components/DownloadResultButtons';
import InfoFieldsDisplay from 'src/components/InfoFieldsDisplay';
import { useIsFocused } from '@react-navigation/native';
import DisplayCsvTableComponentV2 from 'src/components/DisplayCsvTableComponentV2';
import { MANUAL_GRAIN_ANALYSIS } from 'src/constants';

export default function ResultsPage() {

    const [showSCUserView, setShowSCUserView] = React.useState(false);

    const userGroups = useSelector((store: IStore) => store.user.userProfile.response?.groups);
    const resultEditEnabled = useSelector((store: IStore) => store.user.userProfile.response?.settings.result_edit_enabled) || false;

    useEffect(() => {
        if (userGroups && userGroups.includes("scAppUser")) {
            setShowSCUserView(true)
        }
    }, [userGroups]);

    const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
    const job = useSelector((store: IStore) => store.job.jobStatus.job);
    const jobStatus = useSelector((store: IStore) => store.job.currentJobStatus);

    const isFocused = useIsFocused();

    if (!isFocused) {
        return <Box variant={"main"} style={containerStyle.mainContainer}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ padding: 12, textAlign: 'center' }}>Loading...</Text>
            </View>
        </Box>
    }

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            {(jobStatus == JobStatus.JOB_COMPLETED && job) ? (<>
                <ScrollView>
                    <View style={{ flexDirection: 'column' }}>
                        <DownloadResultButtons />
                        <GrainTopLevelAnalysisTable />
                        {
                            job.results?.cumulative_analysis_csv !== undefined &&
                                job.results?.cumulative_analysis_csv !== "" ?
                                <DisplayCsvTableComponentV2
                                    title={"Cumulative Analysis"}
                                    columnsToDisplay={[0, 1, 2]}
                                    editableColumns={[1, 2]}
                                    isEditable={resultEditEnabled || job.productName === MANUAL_GRAIN_ANALYSIS}
                                    jobId={job.jobId}
                                    result_field={"cumulative_analysis_csv"}
                                    csvFileUrl={job.results?.cumulative_analysis_csv} />
                                : <GrainCumulativeAnalysisTable />
                        }
                        {
                            job.results?.rejection_analysis_display_csv !== undefined &&
                                job.results?.rejection_analysis_display_csv !== "" ?
                                <DisplayCsvTableComponentV2
                                    isEditable={false}
                                    title={"Rejection Analysis"}
                                    columnsToDisplay={[0, 1, 2, 3, 4, 5]}
                                    csvFileUrl={job.results?.rejection_analysis_display_csv} />
                                : <DisplayCsvTableComponentV2
                                    title={"Rejection Analysis"}
                                    isEditable={resultEditEnabled || job.productName === MANUAL_GRAIN_ANALYSIS}
                                    columnsToDisplay={[1, 2, 3, 4, 5]}
                                    editableColumns={[1, 3]}
                                    jobId={job.jobId}
                                    result_field={"rejection_analysis_csv"}
                                    total_kernels={job.results?.grain_count}
                                    csvFileUrl={job.results?.rejection_analysis_csv} />
                        }
                        {
                            (
                                job.results?.particle_distribution_csv !== undefined &&
                                job.results?.particle_distribution_csv !== "") ?
                                <DisplayCsvTableComponentV2
                                    title={"Particle distribution by Length"}
                                    isEditable={false}
                                    columnsToDisplay={[0, 1, 2, 3, 4, 5]}
                                    csvFileUrl={job.results?.particle_distribution_csv} />
                                : <>
                                    {
                                        job.results?.indi_grain_csv !== undefined &&
                                        job.results?.indi_grain_csv !== "" &&
                                        <>
                                            <ParticleDistributionTable
                                                grainId={job.grainId}
                                                profileId={job.profileId}
                                                title={"Particle distribution by Length"}
                                                colIndex={1}
                                                lower={0}
                                                upper={30}
                                                stepSize={6}
                                                csvUrl={job.results?.indi_grain_csv} />
                                            <ParticleDistributionTable
                                                grainId={job.grainId}
                                                profileId={job.profileId}
                                                title={"Particle distribution by Width"}
                                                colIndex={2}
                                                lower={0}
                                                upper={5}
                                                stepSize={1}
                                                csvUrl={job.results?.indi_grain_csv} />
                                            <ParticleDistributionTable
                                                grainId={job.grainId}
                                                profileId={job.profileId}
                                                title={"Particle distribution by Area"}
                                                colIndex={3}
                                                lower={0}
                                                upper={30}
                                                stepSize={6}
                                                csvUrl={job.results?.indi_grain_csv} />
                                        </>
                                    }
                                </>
                        }
                        <InfoFieldsDisplay info={job?.info} />
                    </View>
                </ScrollView>
            </>) : (<>
                <View
                    style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text
                        style={{ padding: 12, textAlign: 'center' }}>Select an existing sample from the History page or scan a new one from the home page to view its detailed analysis!
                    </Text>
                </View>
            </>)}
        </Box>
    );

}