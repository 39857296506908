import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Auth as Authorization } from 'aws-amplify';
import { Box, Button, FormControl, Icon, IconButton, Input, VStack } from 'native-base';
import { containerStyle } from 'src/styles/containerStyle';
import { inputStyle } from 'src/styles/inputStyle';
import ProgressBar from 'src/components/ProgressBar';
import InweonLogo from 'src/components/InweonLogo';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { formProps } from 'src/styles/formProps';

interface ForceChangePasswordFormState {
    password: string
}

export default function ForceChangePasswordPage({ route, navigation }) {

    const [securePasswordEntry, setSecurePasswordEntry] = React.useState(true);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

    const handleCompleteNewPassword = async () => {
        setShowLoadingIndicator(true)
        Authorization.completeNewPassword(route.params.user, formData.password).then(async () => {
            navigation.navigate('LoginPage')
            setShowLoadingIndicator(false)
        }).catch((e) => {
            console.log('error occurred', e)
            setErrors({
                ...errors,
                formError: e.message
            });
            setShowLoadingIndicator(false)
        });
    }

    const [formData, setData] = React.useState<ForceChangePasswordFormState>({
        password: ''
    });

    const [errors, setErrors] = React.useState({});

    const validate = () => {
        if (formData.password === undefined
            || formData.password.length === 0) {
            setErrors({
                password: 'Password is required'
            });
            return false;
        } else if (formData.password.length < 8) {
            setErrors({
                password: 'Password must be at least 8 characters'
            });
            return false;
        }

        return true;
    };

    const onSubmit = () => {
        const isValid = validate();
        console.log('isValid', isValid)
        if (isValid) {
            // submit the data
            handleCompleteNewPassword()
        }
    };

    return (
        <Box variant={"main"} style={containerStyle.mainContainer}>
            <ProgressBar isVisibile={showLoadingIndicator} progressText="Loading..." />
            <ScrollView automaticallyAdjustKeyboardInsets={true}>
                <InweonLogo title="Change your password" />
                <VStack width={formProps.width}
                    style={containerStyle.formContainer} mx="3">
                    <FormControl isRequired>
                        <FormControl.Label>Password</FormControl.Label>
                        <Input
                            secureTextEntry={securePasswordEntry}
                            style={inputStyle.nativeInput}
                            isInvalid={'password' in errors}
                            InputRightElement={
                                <IconButton
                                    onPress={() => {
                                        setSecurePasswordEntry(!securePasswordEntry)
                                    }}
                                    icon={<MaterialCommunityIcons name="eye" />} />
                            }
                            placeholder="********"
                            onSubmitEditing={onSubmit}
                            onChangeText={value => setData({
                                ...formData,
                                password: value
                            })} />
                        {'password' in errors ? <FormControl.ErrorMessage>{errors['password']}</FormControl.ErrorMessage> : null}
                    </FormControl>
                    <Button onPress={onSubmit} mt="5">
                        Set new password
                    </Button>
                    {'formError' in errors ? <Text color="red.500" mt="2">{errors['formError'] || ""}</Text> : null}
                </VStack>
            </ScrollView>
        </Box>
    );
}