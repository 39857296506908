import { put } from "redux-saga/effects";
import * as AppActions from "../../actions/appAction";
import FetchError from "../../../api/errors";

export function* showErrorMessage(error: FetchError | Error): any {
    if (error) {
        console.log('error', error)
        if ((error as FetchError).type === "existing-user") {
            console.log("You have an existing account - please log in.");
        } else if ((error as FetchError).type === 401) {
            console.log("Unauthorized user");
            yield put(AppActions.forceLogout());
        } else if ((error as FetchError).type === 500) {
            console.log("There was an error. Please try again in a few seconds.");
        }
    }
}

export const showSuccessMessage = (text?: string) => {
    if (text) {
        console.log(text);
    }
};