import * as React from 'react';

import HomeStatusBar from '../../components/HomeStatusBar';
import HomeTabs from 'src/components/HomeTabs';

export default function HomePage({ navigation }) {
    return <>
        <HomeStatusBar />
        <HomeTabs navigation={navigation} />
    </>;
}

