import { IUpdateUserSettingsRequest, IUserActivity, IUserProfile, IUserProfileResponse, IUserSettingsRequest, IUserSettingsResponse, IUserUpdateLogoRequest } from "src/models";
import { createAction, createAsyncAction } from "typesafe-actions";

export const reset = createAction('RESET')<void>();

export const setUserSubscriptionStatus = createAction('SET_USER_SUBSCRIPTION_STATUS')<string>();

export const getUserProfile = createAsyncAction(
    "user/USER_PROFILE_REQUEST",
    "user/USER_PROFILE_SUCCESS",
    "user/USER_PROFILE_FAILURE"
)<void, IUserProfileResponse, Error>();

export const logUserActivity = createAsyncAction(
    "scan/LOG_USER_ACTIVITY_REQUEST",
    "scan/LOG_USER_ACTIVITY_SUCCESS",
    "scan/LOG_USER_ACTIVITY_FAILURE"
)<IUserActivity, Boolean, Error>();

export const getUserSettings = createAsyncAction(
    "scan/GET_USER_SETTINGS_REQUEST",
    "scan/GET_USER_SETTINGS_SUCCESS",
    "scan/GET_USER_SETTINGS_FAILURE"
)<IUserSettingsRequest, { key: string, value: IUserSettingsResponse | undefined }, Error>();

export const updateUserSettings = createAsyncAction(
    "scan/UPDATE_USER_SETTINGS_REQUEST",
    "scan/UPDATE_USER_SETTINGS_SUCCESS",
    "scan/UPDATE_USER_SETTINGS_FAILURE"
)<IUpdateUserSettingsRequest, Boolean | undefined, Error>();

export const updateUserLogo = createAsyncAction(
    "scan/UPDATE_USER_LOGO_REQUEST",
    "scan/UPDATE_USER_LOGO_SUCCESS",
    "scan/UPDATE_USER_LOGO_FAILURE"
)<IUserUpdateLogoRequest, Boolean | undefined, Error>();