import * as Effects from "redux-saga/effects";

import * as GrainType from "../actions/grainTypeAction";
import * as GrainProfile from "../actions/grainProfileAction";
import { showErrorMessage } from "./shared";
import { IStore } from "../reducers";
import { IGrainWithProfiles } from "src/models";

const call: any = Effects.call;
const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;
const select: any = Effects.select;

const RICE_GRAIN_ID = "18"

export const getCachedGrainWithProfiles = (state: IStore) => state.user.userProfile.response?.grains;
export const getGrainId = (state: any) => state.grainType.grainId;

export function* grainProfiles(action: ReturnType<typeof GrainType.setGrainId>): any {
  try {
    let cachedProfiles = yield select(getCachedGrainWithProfiles);
    
    if (action.payload.toString() !== RICE_GRAIN_ID) {
      const grainWithProfiles: IGrainWithProfiles = cachedProfiles.find((profiles: IGrainWithProfiles) => {
        return profiles.grainId.toString() === action.payload.toString()
      })

      const profileId = grainWithProfiles.profiles && grainWithProfiles.profiles[0].profileId || "1"

      yield put(GrainProfile.setGrainProfiles(grainWithProfiles.profiles || []));
      yield put(GrainProfile.setProfileId(profileId));
    } else {
      const grainWithProfiles: IGrainWithProfiles = cachedProfiles.find((profiles: IGrainWithProfiles) => {
        return profiles.grainId.toString() === action.payload.toString()
      })
      yield put(GrainProfile.setGrainProfileMappings(grainWithProfiles.profileMappings || []));
      yield put(GrainProfile.setGrainProfiles(grainWithProfiles.profiles || []));

      const profileId = grainWithProfiles.profileMappings && grainWithProfiles.profileMappings[0].profileId || "1"

      yield put(GrainProfile.setProfileId(profileId));
    }
  } catch (error: any) {
    showErrorMessage(error);
  }
}

export function* watchSetGrainId() {
  yield takeEvery(GrainType.setGrainId, grainProfiles);
}

export default function* root() {
  yield all([fork(watchSetGrainId)]);
}