import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import * as UploadAction from "../actions/uploadAction";
import * as JobAction from "../actions/jobAction";
import * as UserAction from "../actions/userAction";
import * as api from "../../api";
import { showErrorMessage } from "./shared";
import { IUploadDoubleSidedJobImageRequest, IUploadImageRequest, IUploadJobImageRequest, IUploadJobInfoImageRequest, IUploadLogoImageRequest as IUploadLogoImageRequest } from "../../models/UploadRequest";
import { getFileExtensionFromDataUri } from "src/utils/utils";
import { IStore } from "../reducers";
import { IAllUserSettingsResponse } from "src/models";

export const getUserSettings = (store: IStore) => store.user.allUserSettings.response;

export function* upload(action: ReturnType<typeof UploadAction.upload.request>): any {
  try {
    const request: IUploadJobImageRequest = action.payload;

    const object_key = request.isBase64 ? `input/${request.jobId}.jpg` : `input/${request.jobId}.${getFileExtensionFromDataUri(request.dataObject)}`;

    const uploadRequest: IUploadImageRequest = {
      object_key: object_key,
      isBase64: request.isBase64,
      dataObject: request.dataObject
    };

    const uploadImageResp: boolean | null = yield call(api.uploadImage, uploadRequest);
    if (uploadImageResp && uploadImageResp === true) {
      yield put(JobAction.createJob.request({
        jobId: request.jobId,
        test: false,
        object_key: object_key,
        source: request.source,
        location: request.location,
      }));

      yield put(UploadAction.upload.success(object_key));
      yield put(JobAction.pollJobStatus.request(request.jobId));
    }
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.upload.failure(error));
  }
}

export function* uploadDoubleSided(action: ReturnType<typeof UploadAction.uploadDoubleSided.request>): any {
  try {
    const request: IUploadDoubleSidedJobImageRequest = action.payload;
    if (request.dataObject === undefined || request.dataObject === null
      || request.dataObject.length !== 2) {
      throw Error("Invalid data object");
    }

    const object_keys = [];

    const userSettings: IAllUserSettingsResponse = yield select(getUserSettings);

    for (let i = 0; i < request.dataObject.length; i++) {
      if (request.dataObject[i] === undefined || request.dataObject[i] === null) {
        throw Error("Invalid data object");
      }

      const fileExtension = `jpg`;

      const device_key = `device_${i + 1}`;

      const object_key = `input/${request.jobId}/${device_key}.${fileExtension}`

      const uploadRequest: IUploadImageRequest = {
        object_key: object_key,
        isBase64: request.isBase64,
        dataObject: request.dataObject[i]
      };

      const uploadImageResp: boolean | null = yield call(api.uploadImage, uploadRequest);
      if (uploadImageResp && uploadImageResp === true) {
        object_keys.push(object_key);
      } else {
        throw Error("Error uploading image");
      }
    }

    const object_dir_key = `input/${request.jobId}/`;

    yield put(JobAction.createJob.request({
      jobId: request.jobId,
      test: false,
      object_key: object_dir_key,
      source: request.source,
      location: request.location,
    }));

    yield put(UploadAction.uploadDoubleSided.success(object_dir_key));
    yield put(JobAction.pollJobStatus.request(request.jobId));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.uploadDoubleSided.failure(error));
  }
}

export function* uploadLogo(action: ReturnType<typeof UploadAction.uploadLogo.request>): any {
  try {
    const request: IUploadLogoImageRequest = action.payload;

    const fileExtension = request.isBase64 ? getFileExtensionFromDataUri(request.dataObject) : `png`;
    const objectKey = request.type === "org" ? `profile/org/${request.orgId}.${fileExtension}` : `profile/user/${request.username}.${fileExtension}`;

    const uploadRequest: IUploadImageRequest = {
      object_key: objectKey,
      isBase64: request.isBase64,
      dataObject: request.dataObject
    };

    const uploadImageResp: boolean | null = yield call(api.uploadImage, uploadRequest);
    if (uploadImageResp
      && uploadImageResp === true
      && request.username !== undefined) {
      yield put(UserAction.updateUserLogo.request({
        username: request.username,
        logo: objectKey
      }));

      yield put(UploadAction.uploadLogo.success(objectKey));
    }
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.uploadLogo.failure(error));
  }
}

export function* uploadJobInfoImage(action: ReturnType<typeof UploadAction.uploadJobInfoImage.request>): any {
  try {
    const request: IUploadJobInfoImageRequest = action.payload;

    const fileExtension = request.isBase64 ? getFileExtensionFromDataUri(request.dataObject) : `png`;
    
    const objectKey = `output/${request.jobId}/job_info/${request.fieldName}.${fileExtension}`;
    
    const uploadRequest: IUploadImageRequest = {
      object_key: objectKey,
      isBase64: request.isBase64,
      dataObject: request.dataObject
    };

    const uploadImageResp: boolean | null = yield call(api.uploadImage, uploadRequest);
    if (uploadImageResp
      && uploadImageResp === true) {
      
      yield put(UploadAction.uploadJobInfoImage.success({
        jobId: request.jobId,
        fieldName: request.fieldName,
        s3Path: objectKey
      }));
    }
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.uploadJobInfoImage.failure({
      jobId: action.payload.jobId,
      fieldName: action.payload.fieldName,
      error: error
    }));
  }
}

/*
 * WATCHERS
 */

export function* watchUpload() {
  yield takeEvery(UploadAction.upload.request, upload);
}

export function* watchUploadDoubleSided() {
  yield takeEvery(UploadAction.uploadDoubleSided.request, uploadDoubleSided);
}

export function* watchUploadLogo() {
  yield takeEvery(UploadAction.uploadLogo.request, uploadLogo);
}

export function* watchUploadJobInfoImage() {
  yield takeEvery(UploadAction.uploadJobInfoImage.request, uploadJobInfoImage);
}

export default function* root() {
  yield all([fork(watchUpload),
  fork(watchUploadDoubleSided),
  fork(watchUploadJobInfoImage),
  fork(watchUploadLogo)]);
}